/* Common imports */
import React from "react";

/* mui imports */
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import { Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import { property_category } from "../../lib/data/search-filters";
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../redux-magic/thunks";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomHomeWorkOutlinedIcon = styled(HomeWorkOutlinedIcon)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));
const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

type VisibilityConfig = {
	[key: string]:
		| {
				[key: string]: string[];
		  }
		| string[];
};

export const _e_property_type = ({
	search_id,
	property_category_value,
	property_type,
	dispatch,
}: {
	search_id: SearchFilterId;
	property_category_value: string;
	property_type: StateInterface["search_filters_state"]["property_type"];
	dispatch: Function;
}) => {
	const router = useRouter();

	// const [slide, setSlide] = React.useState<boolean>(property_type?.is_accordion_open);

	const handleUpdatePropertyType = (value: Array<SearchFilterOptionTextActive>) => {
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: property_type.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		const isPropertyTypeSelected = property_type ? (property_type.selected_options.length != 0 ? true : false) : false;

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isPropertyTypeSelected }));

		const updateFilterVisibilityAndAccordion = (item: SearchFilterId, isVisible: boolean, isAccordionOpen: boolean) => {
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: item, isVisible }));
			dispatch(updateSearchFilterIsAccordionOpenThunk({ searchFilterId: item, isAccordionOpen }));
		};

		if (isPropertyTypeSelected) {
			property_type?.affects.forEach((item: SearchFilterId) => {
				const selectedPropertyType = property_type
					? (property_type.selected_options[0] as SearchFilterOptionText)
						? (property_type.selected_options[0] as SearchFilterOptionText).id
						: ""
					: "";

				const visibilityConfig: VisibilityConfig = {
					residential: {
						plot: ["area", "property_status", "amenities", "unit_facing", "plot_dimensions"],
						land_residential: ["area", "property_status", "land_facing"],
						apartment: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
						],
						penthouse: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						villa: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						builder_floor_apartment: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						co_living: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						row_house: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						independent_house: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
					},
					warehousing: ["area", "property_status"],
					agricultural: {
						land_agricultural: ["area"],
						farm_plot: ["area", "plot_dimensions", "property_status", "unit_facing", "amenities"],
						farm_house: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
					},
					industrial: {
						factory: ["area", "property_status"],
						sez: ["area", "property_status"],
						land_industrial: ["area", "land_facing"],
						mining: ["area"],
					},
					commercial: {
						co_working: ["area", "seats", "property_status", "amenities", "unit_facing"],
						land_commercial: ["area", "land_facing"],
						building: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						sez: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						educational: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						shop: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						retail: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						hospitality: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						healthcare: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						office_space: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
					},
				};

				const applicableFilters =
					(visibilityConfig[property_category_value] as { [key: string]: string[] })?.[selectedPropertyType] || [];

				const isVisible = applicableFilters.includes(item);

				updateFilterVisibilityAndAccordion(item, isVisible, isVisible);
			});
		} else {
			property_type.affects.forEach((item) => {
				updateFilterVisibilityAndAccordion(item, false, false);
			});
		}
	}, [property_type, search_id, dispatch, property_category_value]);

	return (
		<Container>
			<HeaderContainer>
				<CustomHomeWorkOutlinedIcon
				// sx={{
				// 	margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
				// }}
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "نوع العقار" : router.locale === "kn-IN" ? "ಆಸ್ತಿ ಪ್ರಕಾರ" : "Property type"}
				</Heading>
				{/* {property_type?.selected_options.length != 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			{/* <Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			> */}
			<CheckBoxContainer>
				{property_type?.active_options.map((item: SearchFilterOptionTextActive) => (
					<CheckBoxDiv
						disableRipple
						disableTouchRipple
						key={item.id}
						onClick={() => {
							const isSelected = item.id === property_category?.selected_options[0]?.id;
							isSelected
								? handleUpdatePropertyType([] as Array<SearchFilterOptionTextActive>)
								: handleUpdatePropertyType([item] as Array<SearchFilterOptionTextActive>);
						}}
						sx={{
							background: item.id === property_type?.selected_options[0]?.id ? "#FC801933" : "",
							border: item?.id === property_type?.selected_options[0]?.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
						}}
					>
						{/* {item.id === property_type.selected_options[0]?.id ? <DoneIcon /> : <AddIcon />} */}
						<Text
							variant="body1"
							sx={{
								fontWeight: item?.id === property_type?.selected_options[0]?.id ? "500" : "400",
							}}
						>
							{item.title === "Co-living" ? "Co-living/PG" : item.title}
						</Text>
					</CheckBoxDiv>
				))}
			</CheckBoxContainer>
			{/* </Slide> */}
		</Container>
	);
};
