/* Common imports */

import React from "react";

/* mui imports */

import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomExploreOutlinedIcon = styled(ExploreOutlinedIcon)(({ theme }) => ({
	marginRight: "0.5rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));
const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

export const _r_land_facing = ({
	search_id,
	land_facing,
	dispatch,
}: {
	search_id: SearchFilterId;
	land_facing: StateInterface["search_filters_state"]["land_facing"];
	dispatch: Function;
}) => {
	// const [slide, setSlide] = React.useState<boolean>(false);

	/* lets create a function to remove the data from the array */

	const handleRemoveData = (item: SearchFilterOption) => {
		// now lets remove the whole object from the array
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: land_facing.selected_options.filter((i) => i.value !== item.value),
			}),
		);

		// now lets set the is_selected value in active_options to false if the user removes the item

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: land_facing.active_options.map((data: SearchFilterOptionTextActive) => ({
					...data,
					is_selected: item === data ? false : data.is_selected,
				})),
			}),
		);
	};

	const updateLandFacing = (newValue: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: newValue as Array<SearchFilterOption>,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: land_facing.active_options.map((item: SearchFilterOptionTextActive) => ({
					...item,
					is_selected: newValue.some((obj) => obj.id === item.id) ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the land facing is selected or not
		let isLandFacingSelected = land_facing ? (land_facing.selected_options.length !== 0 ? true : false) : false;

		// lets set the isApplied value to true if the land facing is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isLandFacingSelected }));
	}, [land_facing, search_id, dispatch]);

	return (
		<Container>
			<HeaderContainer>
				<CustomExploreOutlinedIcon />
				<Heading variant="body2">Facing</Heading>
				{/* {land_facing.selected_options.length != 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CheckBoxContainer>
				{land_facing.possible_options.map((item: SearchFilterOption) => (
					<CheckBoxDiv
						disableRipple
						disableTouchRipple
						key={item.id}
						onClick={() => {
							land_facing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
								? handleRemoveData(item)
								: updateLandFacing([...land_facing.selected_options, item]);
						}}
						sx={{
							background: land_facing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
								? "rgba(252, 128, 25, 0.16)"
								: "",
							border: land_facing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
								? "1px solid #FC8019"
								: "1px solid #C0C0C0",
						}}
					>
						{/* {land_facing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id) ? (
								<DoneIcon />
							) : (
								<AddIcon />
							)} */}
						<Text variant="body1">{item.title}</Text>
					</CheckBoxDiv>
				))}
			</CheckBoxContainer>
		</Container>
	);
};
