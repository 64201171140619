/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */

import { Button, Typography, useTheme } from "@mui/material";
import {
	agricultural_options_active,
	commercial_options_active,
	industrial_options_active,
	residential_options_active,
	warehousing_options_active,
} from "../../../../lib/data/search-filters/_e_property_type";
import StateInterface from "../../../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";

/* redux imports */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: "0.5rem",
	flexWrap: "wrap",
	paddingTop: "0.5rem",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

const PropertyCategoryMobile = ({
	search_id,
	property_category,
	dispatch,
	TabValue,
	searchanimationsettings,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	property_category: StateInterface["search_filters_state"]["property_category"];
	dispatch: Function;
	TabValue: string;
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filter_state: StateInterface["search_filters_state"];
}) => {
	const [selectedOptions, setSelectedOptions] = React.useState<string>("");
	const theme = useTheme();
	const filterKeys = [
		"budget",
		"area",
		"property_status",
		"floors",
		"furnishing",
		"amenities",
		"unit_facing",
		"listed_by",
		"seats",
		"land_facing",
		"plot_dimensions",
		"bedrooms",
		"bathrooms",
		"balconies",
		"units",
		"security_deposit",
		"available_from",
		"possession_date",
	];

	const ClearAdvancedFilters = () => {
		/*

			* if property category changes we clear advanced filters new options to [] and is_applied to false

			*/

		/*

				* let's get all the applied filters and store it as string array

				*/
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return search_filter_state[key as SearchFilterId].is_applied === true && filterKeys.includes(key);
		});

		/*

				* let's map the filters applied and update the search filter applied to false and selected options to empty array

				*/
		filtersapplied.map((filter) => {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: filter as SearchFilterId,
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: filter as SearchFilterId,
					isVisible: false,
				}),
			);
		});
	};

	const handleClickPropertyChips = (value: Array<SearchFilterOptionTextActive>) => {
		ClearAdvancedFilters();
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		const selectedOptionId = value[0]?.id;

		const updatedActiveOptions = property_category.active_options.map((item) => ({
			...item,
			is_selected: item.id === selectedOptionId,
		}));

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: updatedActiveOptions,
			}),
		);

		const propertyTypeOptions =
			selectedOptionId === "residential"
				? residential_options_active
				: selectedOptionId === "commercial"
					? commercial_options_active
					: selectedOptionId === "agricultural"
						? agricultural_options_active
						: selectedOptionId === "warehousing"
							? warehousing_options_active
							: selectedOptionId === "industrial"
								? industrial_options_active
								: residential_options_active;

		selectedOptionId === "residential"
			? setSelectedOptions("residential")
			: selectedOptionId === "commercial"
				? setSelectedOptions("commercial")
				: selectedOptionId === "agricultural"
					? setSelectedOptions("agricultural")
					: selectedOptionId === "warehousing"
						? setSelectedOptions("warehousing")
						: selectedOptionId === "industrial"
							? setSelectedOptions("industrial")
							: "";

		if (selectedOptionId === "residential") {
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: "bedrooms",
					isVisible: true,
				}),
			);
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Bedroom", "Status", "Listed by", "Facing"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
				}),
			);
		}
		if (selectedOptionId === "commercial" || selectedOptionId === "agricultural") {
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: "bedrooms",
					isVisible: false,
				}),
			);
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
				}),
			);
		}
		if (selectedOptionId === "warehousing" || selectedOptionId === "industrial") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Status", "Listed by"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
				}),
			);
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: "bedrooms",
					isVisible: false,
				}),
			);
		}

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "property_type",
				newOptions: propertyTypeOptions,
			}),
		);

		if (
			property_category.selected_options.length === 0 ||
			property_category.selected_options[0]?.id !== selectedOptionId
		) {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_type",
					newOptions: [],
				}),
			);
		}
	};

	React.useEffect(() => {
		// if (isMobile) {
		if (TabValue === "Buy" || TabValue === "Rent" || TabValue === "Open for business") {
			const isApplied = property_category.selected_options.length !== 0;
			const affectsId = property_category.affects?.[0];

			if (affectsId) {
				dispatch(
					updateSearchFilterVisibilityThunk({
						searchFilterId: affectsId,
						isVisible: isApplied,
					}),
				);
			}

			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
		// }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [TabValue]);

	return (
		<Container>
			{property_category.active_options?.map((item: SearchFilterOptionTextActive) => (
				<CheckBoxDiv
					disableRipple
					disableTouchRipple
					key={item?.id}
					onClick={() => {
						if (property_category?.selected_options[0]?.id !== item.id) {
							handleClickPropertyChips([item] as Array<SearchFilterOptionTextActive>);
						}
					}}
					sx={{
						fontSize: "1rem",
						backgroundColor: property_category?.selected_options[0]?.id === item.id ? "#FC801933" : "transparent",
						color: "black",
						border: property_category?.selected_options[0]?.id === item.id ? "1px solid #FC8019" : "1px solid #C0C0C0",

						"&:hover": {
							backgroundColor: property_category?.selected_options[0]?.id === item.id ? "#FC801933" : "transparent",
							boxShadow: "none",
						},
					}}
				>
					<Text
						variant="body1"
						sx={{ fontWeight: property_category?.selected_options[0]?.id === item.id ? 500 : 400 }}
					>
						{item.title}
					</Text>
				</CheckBoxDiv>
			))}
		</Container>
	);
};
export default PropertyCategoryMobile;
