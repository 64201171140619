/* Common imports */

import React from "react";

/* mui imports */
import { Checkbox, FormControlLabel, FormGroup, Stack, useTheme } from "@mui/material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/thunks";

/* redux imports */

const ListedByComponent = ({
	search_id,
	search_filter_state,
	dispatch,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["listed_by"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const [save, setSave] = React.useState<Array<SearchFilterOption>>([]);

	const handleClickListedByChips = (value: Array<SearchFilterOption>, isChecked: boolean) => {
		let updatedSave;
		if (isChecked) {
			// Merge new values with existing ones and remove duplicates
			updatedSave = Array.from(new Set([...save, ...value]));
		} else {
			// Remove unchecked values from the save state
			updatedSave = save.filter((option) => option.id !== value[0].id);
		}

		// Update the save state with the new list of options
		setSave(updatedSave);

		// Dispatch the update with the new, deduplicated list of options
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: updatedSave,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);
	};
	React.useEffect(() => {
		if (search_filter_state.selected_options.length === 0) {
			setSave([]);
		}
	}, [search_filter_state]);

	return (
		<Stack
			direction="row"
			spacing={3}
			// marginBottom={2}
		>
			<FormGroup
				sx={{
					display: "flex",
					flexDirection: "row",
					[theme.breakpoints.down("xsPlus")]: { paddingLeft: "1rem" },
				}}
			>
				{search_filter_state.active_options
					.filter((item) => item.id !== "professional" && item.id !== "user")
					.map((data) => (
						<FormControlLabel
							key={data.id}
							control={
								<Checkbox
									checked={save.some((option) => option.id === data.id)}
									onChange={(event) => {
										handleClickListedByChips([data], event.target.checked);
									}}
								/>
							}
							label={data.title === "Landowner" ? "Owner" : data.title === "Developer" ? "Builder" : data.title}
						/>
					))}
			</FormGroup>
		</Stack>
	);
};
export default ListedByComponent;
