/* Common imports */
import React from "react";
/* mui imports */

import Brightness1Icon from "@mui/icons-material/Brightness1";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SingleBedOutlinedIcon from "@mui/icons-material/SingleBedOutlined";
import { Accordion, AccordionSummary, Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { SearchFilterId } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */
const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomCategoryIcon = styled(SingleBedOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

export const _l_seats = ({
	search_id,
	dispatch,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["bedrooms"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const [state, setState] = React.useState<Array<number>>([]);
	const [slide, setSlide] = React.useState<boolean>(false);

	const seats = [
		{
			id: "1-10",
			title: "1-10",
			value: {
				min: 1,
				max: 10,
				unlock_max: false,
			},
		},
		{
			id: "10-20",
			title: "10-20",
			value: {
				min: 10,
				max: 20,
				unlock_max: false,
			},
		},
		{
			id: "20-30",
			title: "20-30",
			value: {
				min: 20,
				max: 30,
				unlock_max: false,
			},
		},
		{
			id: "30-40",
			title: "30-40",
			value: {
				min: 30,
				max: 40,
				unlock_max: false,
			},
		},
		{
			id: "40-50",
			title: "40-50",
			value: {
				min: 40,
				max: 50,
				unlock_max: false,
			},
		},
		{
			id: "50+",
			title: "50+",
			value: {
				min: 0,
				max: 50,
				unlock_max: true,
			},
		},
	];

	// lets call the useEffect hook to update the state

	React.useEffect(() => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					state.length === 0
						? []
						: [
								{
									id: "seats",
									title: "Seats",
									value: {
										min:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[0] === 5
													? 4
													: state.sort((a, b) => a - b)[0],
										max:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
													? 4
													: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
										unlock_max: state.includes(5) ? true : false,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "seats",
						title: "Seats",
						value: {
							min: state.length === 0 ? 0 : state.sort((a, b) => a - b)[0] === 5 ? 4 : state.sort((a, b) => a - b)[0],
							max:
								state.length === 0
									? 0
									: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
										? 4
										: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
							unlock_max: state.includes(5) ? true : false,
						},
					},
				],
			}),
		);
	}, [state, dispatch, search_id]);

	React.useEffect(() => {
		const value = search_filter_state.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
	}, [search_filter_state, search_id, dispatch]);

	/* lets create a function to remove the data from the array */

	const handleRemoveData = (value: number) => {
		//lets remove the value from the array

		// lets find the index of the value

		const index = state.indexOf(value);

		// lets remove the value

		if (index > -1) {
			const newState = [...state];
			newState.splice(index, 1);
			setState(newState);
		}
	};

	return (
		<Container>
			<HeaderContainer>
				<CustomCategoryIcon /> Seats{" "}
				{state.length !== 0 && (
					<Brightness1Icon sx={{ color: "primary.main", width: "8px", marginTop: "-0.25rem", marginLeft: "0.25rem" }} />
				)}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CheckBoxContainer>
				{seats.map((item: any) => (
					<CheckBoxDiv
						disableRipple
						disableTouchRipple
						key={item.id}
						onClick={() => {
							state.includes(item.value)
								? handleRemoveData(item.value)
								: setState([...state, item.value].sort((a, b) => a - b));
						}}
						sx={{
							background: state.includes(item.value) ? "#FC801933" : "",
							border: state.includes(item.value)
								? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
								: "1px solid #C0C0C0",
						}}
					>
						{/* {state.includes(item.value) ? (
								<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							) : (
								<AddIcon />
							)} */}
						<Text variant="body1">{item.title}</Text>
					</CheckBoxDiv>
				))}
			</CheckBoxContainer>
		</Container>
	);
};
