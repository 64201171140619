/* Common imports */

import React from "react";

/* mui imports */

import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";

import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../redux-magic/thunks";

import {
	agricultural_options_active,
	commercial_options_active,
	industrial_options_active,
	residential_options_active,
	warehousing_options_active,
} from "../../lib/data/search-filters/_e_property_type";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomCategoryIcon = styled(CategoryOutlinedIcon)(({ theme }) => ({
	// margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	margin: "none",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

export const _d_property_category = ({
	search_id,
	property_category,
	dispatch,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	property_category: StateInterface["search_filters_state"]["property_category"];
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"];
}) => {
	// console.log("search_filter_state", property_category);
	const [selectedOptions, setSelectedOptions] = React.useState<string>(property_category.selected_options[0]?.id);

	const theme = useTheme();
	const router = useRouter();

	const filterKeys = [
		"budget",
		"area",
		"property_status",
		"floors",
		"furnishing",
		"amenities",
		"unit_facing",
		"listed_by",
		"seats",
		"land_facing",
		"plot_dimensions",
		"bedrooms",
		"bathrooms",
		"balconies",
		"units",
		"security_deposit",
		"available_from",
		"possession_date",
	];

	const ClearAdvancedFilters = () => {
		/*

			* if property category changes we clear advanced filters new options to [] and is_applied to false

			*/

		/*

				* let's get all the applied filters and store it as string array

				*/
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return search_filter_state[key as SearchFilterId].is_applied === true && filterKeys.includes(key);
		});

		/*

				* let's map the filters applied and update the search filter applied to false and selected options to empty array

				*/
		filtersapplied.map((filter) => {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: filter as SearchFilterId,
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: filter as SearchFilterId,
					isVisible: false,
				}),
			);
		});
	};

	const handleUpdatePropertyCategory = (value: Array<SearchFilterOptionTextActive>) => {
		ClearAdvancedFilters();

		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		const selectedOptionId = value[0]?.id;

		const updatedActiveOptions = property_category.active_options.map((item) => ({
			...item,
			is_selected: item.id === selectedOptionId,
		}));

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: updatedActiveOptions,
			}),
		);

		const propertyTypeOptions =
			selectedOptionId === "residential"
				? residential_options_active
				: selectedOptionId === "commercial"
					? commercial_options_active
					: selectedOptionId === "agricultural"
						? agricultural_options_active
						: selectedOptionId === "warehousing"
							? warehousing_options_active
							: selectedOptionId === "industrial"
								? industrial_options_active
								: residential_options_active;

		if (selectedOptionId === "residential") {
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: "bedrooms",
					isVisible: true,
				}),
			);
		} else {
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: "bedrooms",
					isVisible: false,
				}),
			);
		}

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "property_type",
				newOptions: propertyTypeOptions,
			}),
		);

		if (
			property_category.selected_options.length === 0 ||
			property_category.selected_options[0]?.id !== selectedOptionId
		) {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_type",
					newOptions: [],
				}),
			);
		}
	};

	React.useEffect(() => {
		// lets check if the property category is selected
		let isPropertyCategorySelected = property_category
			? property_category.selected_options.length !== 0
				? true
				: false
			: false;

		// lets set the property category is applied to true if the property category is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isPropertyCategorySelected }));

		//lets check if the property category is affects any other filters
		let propertyCategoryAffects = property_category
			? (property_category.affects[0] as SearchFilterId)
			: (" " as SearchFilterId);

		// lets update the visibility of the affected filter
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: propertyCategoryAffects,
				isVisible: isPropertyCategorySelected,
			}),
		);

		// lets set the accordon value of the affected filter to true if the property category is selected

		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: propertyCategoryAffects,
				isAccordionOpen: isPropertyCategorySelected,
			}),
		);
	}, [property_category, search_id, dispatch]);

	/*

		* Pre applying property type if the property category is chandged

		*/

	React.useEffect(() => {
		setSelectedOptions(property_category.selected_options[0]?.id);
	}, [property_category.selected_options]);

	React.useEffect(() => {
		const dispatchPropertyTypeUpdate = (id: string, title: string, value: string) => {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_type",
					newOptions: [
						{
							id,
							title,
							value,
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "property_type",
					isApplied: true,
				}),
			);
		};
		// console.log("selectedOptionsoutsideif", selectedOptions === "");
		if (selectedOptions !== "") {
			// console.log("selectedOptions", selectedOptions);
			switch (selectedOptions) {
				case "residential":
					dispatchPropertyTypeUpdate("apartment", "Apartment", "Apartment");

					break;
				case "commercial":
					dispatchPropertyTypeUpdate("building", "Building", "Building");

					break;
				case "agricultural":
					dispatchPropertyTypeUpdate("land_agricultural", "Land (Agricultural)", "Land (Agricultural)");

					break;
				case "warehousing":
					dispatchPropertyTypeUpdate("warehouse", "Warehouse", "Warehouse");

					break;
				case "industrial":
					dispatchPropertyTypeUpdate("factory", "Factory", "Factory");

					break;
				default:
					null;
					break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	return (
		<Container>
			<HeaderContainer>
				<CustomCategoryIcon />
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "فئة العقار" : router.locale === "kn-IN" ? "ಆಸ್ತಿ ವರ್ಗ" : "Property category"}
				</Heading>
				{/* {property_category.is_applied && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			{/* <Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			> */}
			<CheckBoxContainer>
				{property_category.active_options.map((item: SearchFilterOptionTextActive) => (
					<CheckBoxDiv
						disableRipple
						disableTouchRipple
						key={item.id}
						onClick={() => {
							const isSelected = item.id === property_category?.selected_options[0]?.id;
							// isSelected
							// 	? handleUpdatePropertyCategory([] as Array<SearchFilterOptionTextActive>)
							handleUpdatePropertyCategory([item] as Array<SearchFilterOptionTextActive>);
						}}
						sx={{
							background: item.id === property_category?.selected_options[0]?.id ? "rgba(252, 128, 25, 0.16)" : "",
							border:
								item?.id === property_category?.selected_options[0]?.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
							fontWeight: item?.id === property_category?.selected_options[0]?.id ? "500" : "400",
						}}
					>
						{/* {item.id === property_category?.selected_options[0]?.id ? (
								<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							) : (
								<AddIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							)} */}
						<Text
							variant="body1"
							sx={{
								fontWeight: item?.id === property_category?.selected_options[0]?.id ? "500" : "400",
							}}
						>
							{item.title}
						</Text>
					</CheckBoxDiv>
				))}
			</CheckBoxContainer>
			{/* </Slide> */}
		</Container>
		// </CustomAccordion>
	);
};
