/*

? First, let's import the required components.

*/

import { ArrowBack, ArrowForward, FilterAltOutlined, LocationOnOutlined } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TuneIcon from "@mui/icons-material/Tune";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import CloseIcon from "@mui/icons-material/Close";

import {
	Badge,
	Button,
	Chip,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
	Slide,
	Snackbar,
	SnackbarContent,
	Stack,
	SwipeableDrawer,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";
import React from "react";
import { property_category_options_active } from "../../../../lib/data/search-filters/_d_property_category";
import PreparePriceString from "../../../../lib/PreparePriceString";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOptionValueRange } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	setSearchFilterMetricesThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";

import { residential_options_active } from "../../../../lib/data/search-filters/_e_property_type";
import { interiors_and_decor_options_active } from "../../../../lib/data/search-filters/_x_service_subtype";
import BeegruButton from "../../../common-components/buttons/BeegruButton";
import FilterMobileComponent from "../../../search/FilterMobileComponent";
import BudgetComponent from "../SearchComponent/BudgetComponent";
import AreaMobile from "./AreaMobile";
import BedroomsMobile from "./BedroomsMobile";
import NoOfSeatsMobile from "./NoOfSeatsMobile";
import PropertyCategoryMobile from "./PropertyCategoryMobile";
import PropertyTypeMobile from "./PropertyTypeMobile";
import ServiceCategoryMobile from "./ServiceCategoryMobile";
import ServiceSubTypeMobile from "./ServiceSubTypeMobile";
import TransactionType from "./TransactionTypeMobile";

const SearchResultsContainer = styled("div")(({ theme }) => ({
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	[theme.breakpoints.up("md1190")]: {
		display: "none",
	},
}));

const OverflowQuickfiltersContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	overflowX: "scroll",
	width: "100%",
	gap: "0.5rem",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

const ContainerNewFilters = styled("div")(({ theme }) => ({
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	background: "#FAFAFA",
	height: "4rem",
	[theme.breakpoints.down("sm")]: {
		position: "fixed",
		zIndex: 4,
		background: theme.palette.background.paper,
		height: "auto",
	},
	[theme.breakpoints.up("md1190")]: {
		display: "none",
	},
}));

const FilterChip = styled(IconButton)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "0rem 0.5rem 0rem 0.5rem",
	height: "1.75rem",
	gap: "0.25rem",
	borderRadius: "8px",
	fontWeight: 500,
	color: theme.palette.text.primary,
	background: theme.palette.background.paper,
	[theme.breakpoints.down("md1220")]: { padding: "1rem" },
	[theme.breakpoints.down("sm")]: { padding: "0rem 0.5rem 0rem 0.5rem" },
}));

const DrawerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	width: "100%",
	gap: "0.5rem",
	borderRadius: "16px",
}));

const CustomSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "space-between",
		// borderRadius: "16px 16px 0px 0px",
		width: "100%",
		height: "100%",
		background: theme.palette.background.default,
	},
}));

const PropertyChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "0rem 0.25rem 0rem 0.5rem",
	height: "1.75rem",
	gap: "0.25rem",
	borderRadius: "8px",
	fontWeight: 500,
	color: theme.palette.text.primary,
	background: theme.palette.background.paper,
	"& .MuiChip-label": {
		padding: "0rem",
		fontWeight: 400,
	},
	"& .MuiChip-deleteIcon": {
		margin: "0rem 0rem 0rem 0rem",
		color: theme.palette.text.primary,
		fontSize: "1rem",
		fontWeight: 400,
	},
	[theme.breakpoints.down("md1220")]: { padding: "1rem" },
	[theme.breakpoints.down("sm")]: { padding: "0rem 0.25rem 0rem 0.5rem" },
})) as typeof Chip;

// const LocationChips = styled(Chip)(({ theme }) => ({
// 	display: "flex",
// 	alignSelf: "stretch",
// 	borderRadius: "8px",
// 	gap: "0.5rem",
// })) as typeof Chip;

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "0.875rem",
	backgroundColor: theme.palette.background.paper,
	padding: "1.25rem 1rem 1rem 1rem",
	// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	zIndex: 3000,
}));

const TitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-sart",
	alignItems: "center",
	gap: "0.5rem",
}));

const ButtonCompoent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "1rem",
	zIndex: 3,
}));

const TypographyHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
}));

const StickyLocationAndFilterContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	height: "100%",
	padding: "0rem 0.75rem 0rem 0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const LocationContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	border: "1px solid #E0E0E0",
	borderRadius: "8px",
	width: "100%",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	gap: "0.5rem",
}));

// const LocationContainer = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "row",
// 	justifyContent: "flex-start",
// 	alignItems: "center",
// 	width: "100%",
// 	height: "100%",
// 	padding: "0rem 0rem 0rem 0rem",
// 	gap: "0rem",
// 	[theme.breakpoints.up("sm")]: {
// 		display: "none",
// 	},
// }));

// const HeaderLocationIconContainer = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	padding: "0.5rem",
// 	alignItems: "center",
// }));

// const LocationMainContainer = styled(Typography)(({ theme }) => ({
// 	display: "flex",
// 	paddingRight: "1rem",
// 	width: "100%",
// 	height: "100%",
// 	[theme.breakpoints.up("sm")]: {
// 		display: "none",
// 	},
// }));

const Search = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
	search_filters_metrices,
	source,
	search_api_response,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
	source?: string;
	search_api_response: StateInterface["SeacrhApiResponse"];
}) => {
	const theme = useTheme();

	const [trigger, setTrigger] = React.useState(false);

	const DrawerOpen = () => {
		setTrigger(true);
	};

	const DrawerClose = () => {
		setTrigger(false);
	};

	/*

		* Buy, rent ofb ,sservices and business users

	*/

	const [value, setValue] = React.useState(
		search_filter_state.search_type.selected_options[0]?.id === "properties"
			? search_filter_state.transaction_type.selected_options[0]?.id === "buy"
				? "Buy"
				: search_filter_state.transaction_type.selected_options[0]?.id === "rent"
					? "Rent"
					: "Open for business"
			: search_filter_state.search_type.selected_options[0]?.id === "services"
				? "Services"
				: "Business Users",
	);

	// const [ofbValue, setOfbValue] = React.useState(
	// 	search_filter_state.transaction_type.selected_options[0]?.id === "ofb" ? "Open for business" : "",
	// );

	// React.useEffect(() => {
	// 	setOfbValue(search_filter_state.transaction_type.selected_options[0]?.id === "ofb" ? "Open for business" : "");
	// }, [search_filter_state.transaction_type]);

	// const handleofbChange = () => {
	// 	dispatch(
	// 		updateSearchFilterSelectedOptionsThunk({
	// 			searchFilterId: "transaction_type",
	// 			newOptions: [
	// 				{
	// 					id: "ofb",
	// 					title: "Open for business",
	// 					value: "ofb",
	// 				},
	// 			],
	// 		}),
	// 	);
	// 	dispatch(
	// 		updateSearchFilterSelectedOptionsThunk({
	// 			searchFilterId: "search_type",
	// 			newOptions: [
	// 				{
	// 					id: "properties",
	// 					title: "Properties",
	// 					value: "properties",
	// 				},
	// 			],
	// 		}),
	// 	);
	// 	dispatch(
	// 		updateSearchFilterAppliedThunk({
	// 			searchFilterId: "transaction_type",
	// 			isApplied: true,
	// 		}),
	// 	);
	// 	dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
	// 	dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

	// 	dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
	// 	dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
	// 	dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

	// 	dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
	// 	dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
	// 	dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));

	// 	dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
	// 	dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));
	// };

	React.useEffect(() => {
		setValue(
			search_filter_state.search_type.selected_options[0]?.id === "properties"
				? search_filter_state.transaction_type.selected_options[0]?.id === "buy"
					? "Buy"
					: search_filter_state.transaction_type.selected_options[0]?.id === "rent"
						? "Rent"
						: "Open for business"
				: search_filter_state.search_type.selected_options[0]?.id === "services"
					? "Services"
					: "Business Users",
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state.search_type]);

	const [searchType, setSearchType] = React.useState(
		search_filter_state.search_type.selected_options[0]?.id === "properties"
			? "Properties"
			: search_filter_state.search_type.selected_options[0]?.id === "services"
				? "Services"
				: "Business Users",
	);

	React.useEffect(() => {
		setSearchType(
			search_filter_state.search_type.selected_options[0]?.id === "properties"
				? "Properties"
				: search_filter_state.search_type.selected_options[0]?.id === "services"
					? "Services"
					: "Business Users",
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state.search_type]);
	/*

	* Function to handle the business users agent, developers, landowners, professionals, users

	*/

	const handleChangeBusinessUsers = (value: string) => {
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true }));
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: "business_profiles",
						title: "Business Profiles",
						value: "business_profiles",
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: [
					{
						id: value === undefined ? "agents" : value.toLocaleLowerCase(),
						title: value === undefined ? "Agents" : value,
						value: value === undefined ? "agents" : value.toLocaleLowerCase(),
					},
				],
			}),
		);

		const business_profile_type = search_filter_state.business_profile_type.active_options.map((item) => ({
			...item,
			is_selected: item.title === value ? true : false,
		}));

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: business_profile_type,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);

		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: "business_profile_type",
				isAccordionOpen: true,
			}),
		);
	};

	const handleChangeSearchType = (newValue: string) => {
		setSearchType(newValue);

		switch (newValue) {
			case "Properties":
				/*

			 * transaction type

			*/
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "rent",
								title: "Rent",
								value: "rent",
							},
						],
					}),
				);

				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				/*

				* Search Type

				*/
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "search_type",
						newOptions: [
							{
								id: "properties",
								title: "Properties",
								value: "properties",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "search_type",
						isApplied: true,
					}),
				);
				/*

				* property category

				*/
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "property_category",
						newOptions: [
							{
								id: "residential",
								title: "Residential",
								value: "residential",
							},
						],
					}),
				);

				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: property_category_options_active,
					}),
				);
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
				/*

				* property type

				*/

				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "property_type",
						newOptions: [
							{
								id: "apartment",
								title: "Apartmentt",
								value: "Apartment",
							},
						],
					}),
				);

				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_type",
						newOptions: residential_options_active,
					}),
				);

				/*

			* updating service is applied and is visible

			*/
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "service_type",
						newOptions: [],
					}),
				);

				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "service_subtype",
						newOptions: [],
					}),
				);

				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));

				/*business Profiles */
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "business_profile_type",
						newOptions: [],
					}),
				);

				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true }));
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true }));

				break;
			case "Services":
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: false,
					}),
				);

				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "search_type",
						newOptions: [
							{
								id: newValue.toLocaleLowerCase(),
								title: newValue,
								value: newValue.toLocaleLowerCase(),
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "search_type",
						isApplied: true,
					}),
				);

				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "service_type",
						newOptions: [
							{
								id: "interiors_and_decor",
								title: "Interiors and Decor",
								value: "interiors_and_decor",
							},
						],
					}),
				);

				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "service_type",
						isApplied: true,
					}),
				);
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "service_subtype",
						newOptions: interiors_and_decor_options_active,
					}),
				);
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "service_subtype",
						newOptions: [
							{
								id: "interior_design",
								title: "Interior Design",
								value: "interior_design",
							},
						],
					}),
				);

				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "service_subtype",
						isApplied: true,
					}),
				);
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: true }));

				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true }));

				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));

				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));

				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "business_profile_type",
						newOptions: [],
					}),
				);

				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true }));
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true }));

				break;
			case "Business Users":
				handleChangeBusinessUsers(
					search_filter_state?.business_profile_type?.selected_options[0]?.title
						? search_filter_state?.business_profile_type?.selected_options[0]?.title
						: "Agents",
				);
				break;
			default:
				null;
				break;
		}
	};

	const handleChange = (newValue: string) => {
		// if (value !== newValue) {
		// 	let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
		// 		return (
		// 			search_filter_state[key as SearchFilterId].is_applied === true &&
		// 			key !== "transaction_type" &&
		// 			key !== "search_type" &&
		// 			key !== "location"
		// 		);
		// 	});
		// 	filtersapplied.map((filter) => {
		// 		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
		// 		dispatch(
		// 			updateSearchFilterSelectedOptionsThunk({
		// 				searchFilterId: filter as SearchFilterId,
		// 				newOptions: [],
		// 			}),
		// 		);
		// 	});
		// }

		setValue(newValue);
		/*

		* Setting the search filter selected options based on the transaction type

		*/
		if (newValue === "Buy" || newValue === "Rent") {
			// dispatch(
			// 	updateSearchFilterActiveOptionsThunk({
			// 		searchFilterId: "property_category",
			// 		newOptions: property_category_options_active,
			// 	}),
			// );
			/*

			* updating service is applied and is visible

			*/
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
			/*

			* updating properties is applied and is visible

			*/
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));

			/*

			 * need to update transaction type as well as search type same with isApplied true.

				! default value is done in index.tsx page for transaction_type

			*/
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [
						{
							id: newValue.toLocaleLowerCase(),
							title: newValue,
							value: newValue.toLocaleLowerCase(),
						},
					],
				}),
			);

			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: true,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: "properties",
							title: "Properties",
							value: "properties",
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
		}
	};

	const router = useRouter();
	const [openBottomDrawerFilters, setOpenBottomDrawerFilters] = React.useState(false);
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		if (open && search_filter_state.location.is_applied === true) {
			setOpen(false);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [search_filter_state]);

	const [isLoading, setIsLoading] = React.useState(false);

	const handleClickSearchButton = () => {
		if (search_filter_state.location.is_applied === false) {
			setOpen(true);
			setOpenBottomDrawerFilters(false);
		} else {
			if (isLoading) return;
			setIsLoading(true);

			if (!searchanimationsettings.StartAnimate) {
				router
					.push("/search", "", { shallow: true })
					.then(() => {
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings.PropertySubCategory,
								HomeAccordion: false,
								OpenLocationDrawer: false,
							}),
						);
						setTrigger(false);
					})
					.catch((error) => {
						console.error("Navigation error:", error);
						setIsLoading(false);
					});
			} else {
				setTrigger(false);
				dispatch(
					setSearchAnimationDetailsThunk({
						StartAnimate: searchanimationsettings.StartAnimate,
						TriggerSearch: true,
						ResetSearch: true,
						PropertySubCategory: searchanimationsettings.PropertySubCategory,
						HomeAccordion: false,
						OpenLocationDrawer: false,
					}),
				);
				dispatch(
					setSearchFilterMetricesThunk({
						boosted: [0],
						non_boosted: [0],
						filter_hash: "",
						no_of_boosted_results: 0,
						no_of_non_boosted_results: 0,
						total_no_of_results: 0,
						boosted_from_other_locations: [0],
						no_of_boosted_from_other_locations: 0,
					}),
				);
			}
			setIsLoading(false);
			setOpenBottomDrawerFilters(false);
		}
	};

	const [slide, setSlide] = React.useState<boolean>(search_filter_state.location.is_applied === true ? true : false);
	React.useEffect(() => {
		setSlide(search_filter_state.location.is_applied === true ? true : false);
	}, [search_filter_state.location.is_applied]);
	const handleDefaultFilters = () => {
		/*

			* Search Type

		*/
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: "properties",
						title: "Properties",
						value: "properties",
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [
					{
						id: "rent",
						title: "Rent",
						value: "rent",
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: true,
			}),
		);
		/*

			* property category

		*/
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "property_category",
				newOptions: [
					{
						id: "residential",
						title: "Residential",
						value: "residential",
					},
				],
			}),
		);

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "property_type",
				newOptions: residential_options_active,
			}),
		);
		/*

			* property type

		*/

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "property_type",
				newOptions: [
					{
						id: "apartment",
						title: "Apartmentt",
						value: "Apartment",
					},
				],
			}),
		);

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));
	};

	const ClearAllFilters = () => {
		/*

		* let's get all the applied filters and store it as string array

		*/
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return search_filter_state[key as SearchFilterId].is_applied === true && key !== "location";
		});

		/*

		* let's map the filters applied and update the search filter applied to false and selected options to empty array

		*/
		filtersapplied.map((filter) => {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: filter as SearchFilterId, isVisible: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: filter as SearchFilterId,
					newOptions: [],
				}),
			);
		});
		handleDefaultFilters();
	};
	function SlideTransition(props: any) {
		return (
			<Slide
				{...props}
				direction="up"
			/>
		);
	}

	let filterTypes = [
		/* 1st Budget */
		search_filter_state.search_type.selected_options[0]?.title === "Business Profiles" ? null : "Budget",

		/* 2nd TransactionType */
		search_filter_state.transaction_type.selected_options.length > 0
			? search_filter_state.transaction_type.selected_options[0]?.title
			: null,

		/* 3rd Bedrooms or Area */
		search_filter_state.property_category?.is_applied &&
		search_filter_state.property_type.selected_options.length > 0 &&
		[
			"apartment",
			"independent_house",
			"villa",
			"co_living",
			"row_house",
			"builder_floor_apartment",
			"penthouse",
			"farm_house",
		].includes(search_filter_state.property_type.selected_options[0]?.id)
			? "Bedrooms"
			: search_filter_state.property_type?.is_applied &&
				  search_filter_state.property_type.selected_options.length > 0 &&
				  [
						"land_residential",
						"land_commercial",
						"land_agricultural",
						"land_industrial",
						"plot",
						"plot_commercial",
						"farm_plot",
						"building",
						"shop",
						"office_space",
						"warehouse",
						"factory",
						"mining",
				  ].includes(search_filter_state.property_type.selected_options[0]?.id)
				? "Area"
				: search_filter_state.property_type?.is_applied &&
					  search_filter_state.property_type.selected_options.length > 0 &&
					  ["co_working"].includes(search_filter_state.property_type.selected_options[0]?.id)
					? "No of seats"
					: null,

		/* 4th Property Category and Type */
		search_filter_state.search_type.selected_options[0]?.id === "services"
			? "Service type"
			: search_filter_state.search_type.selected_options[0]?.id === "properties"
				? "Property type"
				: "Business user type",
	].filter(Boolean);

	const [filterType, setFilterType] = React.useState<string>("");

	const filterTypesHandleClick = (data: string) => {
		setFilterType(data);
		setOpenBottomDrawerFilters(true);
	};

	const [bottomDrawerKey, setBottomDrawerKey] = React.useState<number>(0);

	const DrawerRenderFunctions = (value: any) => {
		if (filterTypes.length === 4 || filterTypes.length === 3) {
			switch (value) {
				case 0:
					return (
						<React.Fragment>
							<div
								style={{
									marginBottom: "1rem",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<TypographyHeading
										variant="body1"
										sx={{
											marginBottom: "0.5rem",
										}}
									>
										{router.locale === "ar-AE"
											? "أدخل السعر"
											: router.locale === "kn-IN"
												? "ಬಜೆಟ್ ನಮೂದಿಸಿ"
												: "Enter Budget"}
									</TypographyHeading>
									<CloseIcon
										fontSize="small"
										sx={{ color: "rgba(0, 0, 0, 0.54)" }}
										onClick={() => setOpenBottomDrawerFilters(false)}
									/>
								</div>

								<BudgetComponent
									search_id="budget"
									dispatch={dispatch}
									search_filter_state={search_filter_state.budget}
									transaction_type={search_filter_state.transaction_type}
								/>
							</div>
							<BeegruButton
								flavor="primary"
								variant="contained"
								size="medium"
								sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
								onClick={handleClickSearchButton}
							>
								{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
							</BeegruButton>
						</React.Fragment>
					);
				case 1:
					return (
						<React.Fragment>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									paddingBottom: "0.5rem",
								}}
							>
								<Typography variant="body1">What are you looking for?</Typography>
								<CloseIcon
									fontSize="small"
									sx={{ color: "rgba(0, 0, 0, 0.54)" }}
									onClick={() => setOpenBottomDrawerFilters(false)}
								/>
							</div>
							<TransactionType
								search_id={"transaction_type"}
								search_filter_state={search_filter_state.transaction_type}
								dispatch={dispatch}
								searchanimationsettings={searchanimationsettings}
								setOpenBottomDrawerFilters={setOpenBottomDrawerFilters}
							/>
						</React.Fragment>
					);
				case 2:
					return (
						<React.Fragment>
							{filterTypes.includes("Bedrooms") ? (
								<BedroomsMobile
									search_id={"bedrooms"}
									bedrooms={search_filter_state.bedrooms}
									dispatch={dispatch}
								/>
							) : filterTypes.includes("Area") ? (
								<AreaMobile
									search_id={"area"}
									area={search_filter_state.area}
									dispatch={dispatch}
								/>
							) : filterTypes.includes("No of seats") ? (
								<NoOfSeatsMobile
									search_id={"seats"}
									search_filter_state={search_filter_state.seats}
									dispatch={dispatch}
								/>
							) : null}
							<BeegruButton
								flavor="primary"
								variant="contained"
								size="medium"
								sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
								onClick={handleClickSearchButton}
							>
								{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
							</BeegruButton>
						</React.Fragment>
					);
				case 3:
					return (
						<React.Fragment>
							{/* Property Filters */}

							{search_filter_state.search_type.selected_options[0]?.title === "Properties" &&
							(search_filter_state.transaction_type.selected_options[0]?.title === "Buy" ||
								search_filter_state.transaction_type.selected_options[0]?.title === "Rent") &&
							filterTypes.includes("Property type") ? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
										gap: "0.5rem",
									}}
								>
									<div
										style={{
											padding: "0rem 0rem 0.5rem 0rem",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
											}}
										>
											<TypographyHeading variant="body1">
												{router.locale === "ar-AE"
													? "اختر فئة العقار"
													: router.locale === "kn-IN"
														? "ನಿಮಗೆ ಸೂಕ್ತವಾದನ್ನು ಆಯ್ಕೆ ಮಾಡಿ"
														: "Select property category"}
											</TypographyHeading>
											<CloseIcon
												fontSize="small"
												sx={{ color: "rgba(0, 0, 0, 0.54)" }}
												onClick={() => setOpenBottomDrawerFilters(false)}
											/>
										</div>
										<PropertyCategoryMobile
											search_id={"property_category"}
											property_category={search_filter_state.property_category}
											dispatch={dispatch}
											TabValue={value}
											searchanimationsettings={searchanimationsettings}
											search_filter_state={search_filter_state}
										/>

										{search_filter_state.property_category.is_applied &&
										search_filter_state.property_category.selected_options[0]?.id !== "warehousing" ? (
											<>
												<TypographyHeading
													sx={{
														paddingTop: "1.5rem",
													}}
													variant="body1"
												>
													{router.locale === "ar-AE"
														? "اختر نوع العقار"
														: router.locale === "kn-IN"
															? "ಆಸ್ತಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
															: "Select property type"}
												</TypographyHeading>

												<PropertyTypeMobile
													search_id="property_type"
													property_category_value={
														search_filter_state.property_category?.selected_options[0]?.value as string
													}
													dispatch={dispatch}
													search_filter_state={search_filter_state.property_type}
													searchanimationsettings={searchanimationsettings}
													TabValue={value}
												/>
											</>
										) : null}
									</div>

									<BeegruButton
										flavor="primary"
										variant="contained"
										size="medium"
										sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
										onClick={handleClickSearchButton}
									>
										{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
									</BeegruButton>
								</div>
							) : null}

							{/* OFB Filters */}

							{search_filter_state.search_type.selected_options[0]?.title === "Properties" &&
							search_filter_state.transaction_type.selected_options[0]?.title === "Open for business" &&
							filterTypes.includes("Property type") ? (
								<div
									style={{
										// display: value === "Open for business" ? "flex" : "none",
										flexDirection: "column",
										width: "100%",
										gap: "0.5rem",
									}}
								>
									<div
										style={{
											padding: "0rem 0rem 0.5rem 0rem",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
											}}
										>
											<TypographyHeading variant="body1">
												{router.locale === "ar-AE"
													? "اختر فئة أو إف بي"
													: router.locale === "kn-IN"
														? "OFB ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
														: "Select OFB category"}
											</TypographyHeading>
											<CloseIcon
												fontSize="small"
												sx={{ color: "rgba(0, 0, 0, 0.54)" }}
												onClick={() => setOpenBottomDrawerFilters(false)}
											/>
										</div>

										<PropertyCategoryMobile
											search_id={"property_category"}
											property_category={search_filter_state.property_category}
											dispatch={dispatch}
											TabValue={value}
											searchanimationsettings={searchanimationsettings}
											search_filter_state={search_filter_state}
										/>

										{search_filter_state.property_category?.selected_options[0]?.id === "warehousing" ||
										search_filter_state.property_category?.selected_options[0]?.id === "public_infrastructure" ||
										search_filter_state.property_category?.selected_options[0]?.id === "joint_development" ||
										search_filter_state.property_category?.selected_options[0]?.id === "joint_venture" ||
										search_filter_state.property_category?.selected_options[0]?.id === undefined ? null : (
											<>
												<TypographyHeading
													sx={{
														paddingTop: "1.5rem",
													}}
													variant="body1"
												>
													{router.locale === "ar-AE"
														? "اختر نوع أو إف بي"
														: router.locale === "kn-IN"
															? "OFB ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
															: "Select OFB type"}
												</TypographyHeading>
												<PropertyTypeMobile
													search_id="property_type"
													property_category_value={
														search_filter_state.property_category?.selected_options[0]?.value as string
													}
													dispatch={dispatch}
													search_filter_state={search_filter_state.property_type}
													searchanimationsettings={searchanimationsettings}
													TabValue={value}
												/>
											</>
										)}
									</div>
									<BeegruButton
										flavor="primary"
										variant="contained"
										size="medium"
										sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
										onClick={handleClickSearchButton}
									>
										{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
									</BeegruButton>
								</div>
							) : null}
						</React.Fragment>
					);
			}
		} else if (filterTypes.length === 2) {
			switch (value) {
				case 0:
					return (
						<React.Fragment>
							<div
								style={{
									marginBottom: "1rem",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<TypographyHeading
										variant="body1"
										sx={{
											marginBottom: "0.5rem",
										}}
									>
										{router.locale === "ar-AE"
											? "أدخل السعر"
											: router.locale === "kn-IN"
												? "ಬಜೆಟ್ ನಮೂದಿಸಿ"
												: "Enter Budget"}
									</TypographyHeading>
									<CloseIcon
										fontSize="small"
										sx={{ color: "rgba(0, 0, 0, 0.54)" }}
										onClick={() => setOpenBottomDrawerFilters(false)}
									/>
								</div>

								<BudgetComponent
									search_id="budget"
									dispatch={dispatch}
									search_filter_state={search_filter_state.budget}
									transaction_type={search_filter_state.transaction_type}
								/>
							</div>
							<BeegruButton
								flavor="primary"
								variant="contained"
								size="medium"
								sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
								onClick={handleClickSearchButton}
							>
								{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
							</BeegruButton>
						</React.Fragment>
					);
				case 1:
					return (
						<React.Fragment>
							{/* Service Filters */}

							{search_filter_state.search_type.selected_options[0]?.title === "Services" &&
							filterTypes[1] === "Service type" ? (
								<div
									style={{
										// display: value === "Services" ? "flex" : "none",
										flexDirection: "column",
										width: "100%",
										gap: "0.5rem",
									}}
								>
									<div
										style={{
											padding: "0rem 0rem 0.5rem 0rem",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
											}}
										>
											<TypographyHeading variant="body1">
												{router.locale === "ar-AE"
													? "اختر فئة الخدمة"
													: router.locale === "kn-IN"
														? "ಸೇವಾ ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
														: "Select service category"}
											</TypographyHeading>
											<CloseIcon
												fontSize="small"
												sx={{ color: "rgba(0, 0, 0, 0.54)" }}
												onClick={() => setOpenBottomDrawerFilters(false)}
											/>
										</div>
										<ServiceCategoryMobile
											search_id={"service_type"}
											search_filter_state={search_filter_state.service_type}
											dispatch={dispatch}
											search_type={search_filter_state.search_type.selected_options[0]?.id as string}
										/>
									</div>

									{search_filter_state.service_type.is_applied === true && (
										<div
											style={{
												paddingBottom: "0.5rem",
											}}
										>
											<TypographyHeading variant="body1">
												{router.locale === "ar-AE"
													? "اختر نوع الخدمة"
													: router.locale === "kn-IN"
														? "ಸೇವಾ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
														: "Select service type"}
											</TypographyHeading>

											<ServiceSubTypeMobile
												search_id={"service_subtype"}
												dispatch={dispatch}
												search_filter_state={search_filter_state.service_subtype}
												search_type={search_filter_state.search_type.selected_options[0]?.id as string}
												service_type={search_filter_state.service_type.selected_options[0]?.id as string}
											/>
										</div>
									)}
									<BeegruButton
										flavor="primary"
										variant="contained"
										size="medium"
										sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
										onClick={handleClickSearchButton}
									>
										{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
									</BeegruButton>
								</div>
							) : null}
						</React.Fragment>
					);
			}
		} else if (filterTypes.length === 1) {
			switch (value) {
				case 0:
					return (
						<React.Fragment>
							{search_filter_state.search_type.selected_options[0]?.title === "Business Profiles" &&
							filterTypes[0] === "Business user type" ? (
								<div
									style={{
										flexDirection: "column",
										width: "100%",
										gap: "0.5rem",
										padding: "0rem 0rem 0.5rem 0rem",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<TypographyHeading variant="body1">
											{router.locale === "ar-AE"
												? "اختر نوع مستخدم الأعمال"
												: router.locale === "kn-IN"
													? "ವ್ಯವಸಾಯ ಬಳಕೆದಾರರ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
													: "Select business user type"}
										</TypographyHeading>
										<CloseIcon
											fontSize="small"
											sx={{ color: "rgba(0, 0, 0, 0.54)" }}
											onClick={() => setOpenBottomDrawerFilters(false)}
										/>
									</div>

									<FormControl>
										<FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
										<RadioGroup
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="row-radio-buttons-group"
											sx={{ gap: "0.5rem", fontSize: "1rem", fontWeight: 400 }}
											value={search_filter_state.business_profile_type.selected_options[0]?.value}
										>
											{search_filter_state.business_profile_type.active_options.map((data, id) => (
												<FormControlLabel
													key={id}
													value={data.value}
													control={<Radio />}
													label={
														data.title === "Landowners"
															? "Owners"
															: data.title === "Professionals"
																? "Service Professionals"
																: data.title
													}
													onChange={() => handleChangeBusinessUsers(data.title)}
												/>
											))}
										</RadioGroup>
									</FormControl>
									<BeegruButton
										flavor="primary"
										variant="contained"
										size="medium"
										sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
										onClick={handleClickSearchButton}
									>
										{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
									</BeegruButton>
								</div>
							) : null}
						</React.Fragment>
					);
			}
		}
	};

	/*

		* Badge calculation for the filter types

	*/

	const [badge, setBadge] = React.useState<number>(0);

	const BadgeCalculation = () => {
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return search_filter_state[key as SearchFilterId].is_applied === true;
		});
		setBadge(filtersapplied.length);
	};
	React.useEffect(() => {
		BadgeCalculation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state]);

	/*

	* Budget Filters display

	*/

	let budgetToDisplay = search_filter_state.budget.selected_options[0]?.value as SearchFilterOptionValueRange;

	const [locationTextValue, setLocationTextValue] = React.useState<string>("");

	React.useEffect(() => {
		if (search_filter_state?.location?.selected_options.length !== 0) {
			const selectedOption = search_filter_state?.location?.selected_options[0]?.value;
			if (typeof selectedOption === "object" && "title" in selectedOption) {
				setLocationTextValue(selectedOption.title);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state]);

	const getStyles = (
		data: string,
		id: number,
		search_filter_state: StateInterface["search_filters_state"],
		filterTypes: number,
	) => {
		/*

		* Conditions to be checked stored in variables

		*/

		const isTransactionApplied = search_filter_state.transaction_type?.is_applied;
		const isPropertyApplied =
			search_filter_state.property_category?.is_applied ||
			search_filter_state.service_type?.is_applied ||
			search_filter_state.business_profile_type?.is_applied;
		const isBudgetApplied = search_filter_state.budget?.is_applied;
		const isBusinessTypeApplied = search_filter_state.business_profile_type?.is_applied;
		const isBedroomsApplied = search_filter_state.bedrooms?.is_applied;
		const isAreaApplied = search_filter_state.area?.is_applied;
		/*

		*default values if condition fails

		*/
		let border = "1px solid rgba(0, 0, 0, 0.12)";
		let background = theme.palette.background.paper;
		let fontWeight = 400;

		/*

		*Conditions to check the filter types and apply the styles

		*/

		if (filterTypes === 4) {
			if (id === 1 && isTransactionApplied) {
				border = "1px solid rgba(252, 128, 25, 0.48)";
				background = "rgba(252, 128, 25, 0.16)";
				fontWeight = 500;
			} else if (id === 3 && isPropertyApplied) {
				border = "1px solid rgba(252, 128, 25, 0.48)";
				background = "rgba(252, 128, 25, 0.16)";
				fontWeight = 500;
			} else if (id === 0 && isBudgetApplied) {
				border = "1px solid rgba(252, 128, 25, 0.48)";
				background = "rgba(252, 128, 25, 0.16)";
				fontWeight = 500;
			} else if (id === 2 && (isBedroomsApplied || isAreaApplied)) {
				border = "1px solid rgba(252, 128, 25, 0.48)";
				background = "rgba(252, 128, 25, 0.16)";
				fontWeight = 500;
			}
		} else if (filterTypes === 2) {
			if (id === 1 && isPropertyApplied) {
				border = "1px solid rgba(252, 128, 25, 0.48)";
				background = "rgba(252, 128, 25, 0.16)";
				fontWeight = 500;
			} else if (id === 0 && isBudgetApplied) {
				border = "1px solid rgba(252, 128, 25, 0.48)";
				background = "rgba(252, 128, 25, 0.16)";
				fontWeight = 500;
			}
		} else if (filterTypes === 1) {
			if (id === 0 && isBusinessTypeApplied) {
				border = "1px solid rgba(252, 128, 25, 0.48)";
				background = "rgba(252, 128, 25, 0.16)";
				fontWeight = 500;
			}
		}

		return { border, background, fontWeight };
	};

	return (
		<React.Fragment>
			{/* <Fab
				color="primary"
				aria-label="add"
				size="small"
				onClick={() => setTrigger(true)}
				sx={{
					position: "fixed",
					bottom: "4.5rem",
					right: "1rem",
					boxShadow: "none",
					[theme.breakpoints.up("sm")]: {
						display: "none",
					},
				}}
			>
				<FilterAltOutlined fontSize="small" />
			</Fab> */}

			<CustomSwipeableDrawer
				anchor="bottom"
				open={trigger}
				onClose={() => setTrigger(false)}
				onOpen={() => setTrigger(true)}
				swipeAreaWidth={156}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					"& .MuiDrawer-paper": {
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						background: theme.palette.background.paper,
					},
				}}
			>
				<Snackbar
					open={open}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					sx={{
						bottom: "70px",
					}}
					TransitionComponent={SlideTransition}
				>
					<SnackbarContent
						sx={{
							borderRadius: "0.85rem",
							color: "#5F2B01",
							background: "#FDF0E5",
							boxShadow: "none",
							display: "flex",
							alignItems: "center",
							fontWeight: 500,
							fontSize: "1rem",
						}}
						message={
							<span style={{ display: "flex", alignItems: "center" }}>
								<WarningAmberIcon style={{ marginRight: "0.75rem" }} />
								{router.locale === "ar-AE"
									? "يرجى إدخال الموقع"
									: router.locale === "kn-IN"
										? "ದಯವಿಟ್ಟು ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
										: "Please enter a location"}
							</span>
						}
					/>
				</Snackbar>

				{/* Transaction Type Tabs */}

				<HeaderContainer>
					<TitleContainer>
						<IconButton
							onClick={(e: any) => {
								DrawerClose();
								e.stopPropagation();
							}}
							sx={{
								padding: "0.25rem",
							}}
						>
							{router.locale === "ar-AE" ? (
								<ArrowForward
									sx={{
										color: theme.palette.text.secondary,
										fontSize: "1.25rem",
										[theme.breakpoints.up("md1190")]: {
											fontSize: "1.5rem",
										},
									}}
								/>
							) : (
								<ArrowBack
									sx={{
										color: theme.palette.text.secondary,
										fontSize: "1.25rem",
										[theme.breakpoints.up("md1190")]: {
											fontSize: "1.5rem",
										},
									}}
								/>
							)}
						</IconButton>

						<Typography variant="body1">
							{router.locale === "ar-AE" ? "الفلاتر" : router.locale === "kn-IN" ? "ಫಿಲ್ಟರ್‌ಗಳು" : "Filters"}
						</Typography>
					</TitleContainer>
					<DrawerContainer
						sx={{
							border: "1px solid rgba(0, 0, 0, 0.12)",
							borderRadius: "1rem",
							height: "auto",
							padding: "0.5rem",
							display: "flex",
							// justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Button
							variant={searchType === "Properties" ? "contained" : "text"}
							onClick={() => handleChangeSearchType("Properties")}
							sx={{
								background: searchType === "Properties" ? "rgba(252, 128, 25, 0.48)" : "transparent",
								width: "50%",
								boxShadow: "none",
								color: theme.palette.text.primary,
								"&:hover": {
									boxShadow: "none",
								},
								textTransform: "none",
								fontWeight: "400",
								borderRadius: "0.75rem",
							}}
						>
							Property
						</Button>
						<Button
							variant={searchType === "Services" ? "contained" : "text"}
							onClick={() => handleChangeSearchType("Services")}
							sx={{
								background: searchType === "Services" ? "rgba(252, 128, 25, 0.48)" : "transparent",
								color: theme.palette.text.primary,
								width: "50%",
								boxShadow: "none",
								"&:hover": {
									boxShadow: "none",
								},

								textTransform: "none",
								fontWeight: "400",
								borderRadius: "0.75rem",
							}}
						>
							Service
						</Button>
						{/* <Button
							variant={searchType === "Business Users" ? "contained" : "text"}
							onClick={() => handleChangeSearchType("Business Users")}
							sx={{
								background: searchType === "Business Users" ? "rgba(252, 128, 25, 0.48)" : "transparent",
								color: theme.palette.text.primary,
								width: "40%",
								boxShadow: "none",
								"&:hover": {
									boxShadow: "none",
								},
								textTransform: "none",
								fontWeight: "400",
								borderRadius: "0.75rem",
							}}
						>
							Business Profile
						</Button> */}
						{/* <Button
							variant={ofbValue === "Open for business" ? "contained" : "text"}
							onClick={() => handleofbChange()}
							sx={{
								background: ofbValue === "Open for business" ? "rgba(252, 128, 25, 0.16)" : "transparent",
								color: ofbValue === "Open for business" ? "#B05911" : theme.palette.text.primary,
								width: "48%",
								boxShadow: "none",
								"&:hover": {
									boxShadow: "none",
								},
								textTransform: "none",
								fontWeight: "400",
								borderRadius: "0.75rem",
							}}
						>
							Open for business
						</Button> */}
					</DrawerContainer>
					{/* Transaction Type Tabs */}
					{!search_filter_state.business_profile_type.is_applied &&
						!search_filter_state.service_type.is_applied &&
						search_filter_state.transaction_type.is_applied && (
							<DrawerContainer
								sx={{
									border: "none",
									borderRadius: "1rem",
									height: "2.5rem",
									padding: "0rem 0.5rem 0rem 0.5rem",
									gap: "1rem",
								}}
							>
								<Button
									variant={value === "Rent" ? "contained" : "text"}
									onClick={() => handleChange("Rent")}
									sx={{
										background: value === "Rent" ? "rgba(252, 128, 25, 0.16)" : "transparent",
										color: value === "Rent" ? "#B05911" : theme.palette.text.primary,
										border: value === "Rent" ? "1px solid #B05911" : "1px solid rgba(0, 0, 0, 0.12)",
										width: "50%",
										boxShadow: "none",
										"&:hover": {
											boxShadow: "none",
										},
										textTransform: "none",
										fontWeight: value === "Rent" ? "500" : "400",
										borderRadius: "0.75rem",
									}}
								>
									Rent
								</Button>
								<Button
									variant={value === "Buy" ? "contained" : "text"}
									onClick={() => handleChange("Buy")}
									sx={{
										background: value === "Buy" ? "rgba(252, 128, 25, 0.16)" : "transparent",
										width: "50%",
										boxShadow: "none",
										color: value === "Buy" ? "#B05911" : theme.palette.text.primary,
										border: value === "Buy" ? "1px solid #B05911" : "1px solid rgba(0, 0, 0, 0.12)",
										"&:hover": {
											boxShadow: "none",
										},
										textTransform: "none",
										fontWeight: value === "Buy" ? "500" : "400",
										borderRadius: "0.75rem",
									}}
								>
									Sale
								</Button>
							</DrawerContainer>
						)}
				</HeaderContainer>

				{/* Filters Modal */}

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						height: "100%",
						overflowY: "scroll",
						gap: "1.5rem",
						padding: "0rem 0.5rem 0.25rem 0.5rem",
					}}
				>
					<Slide
						direction="up"
						in={slide}
						mountOnEnter
						unmountOnExit
					>
						<DrawerContainer
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								alignContent: "center",
								gap: "0.5rem",
							}}
						>
							{!searchanimationsettings.StartAnimate ? null : (
								<FilterMobileComponent
									search_filter_state={search_filter_state}
									dispatch={dispatch}
								/>
							)}
						</DrawerContainer>
					</Slide>
				</div>

				{search_filter_state.location.is_applied ? (
					<ButtonCompoent
						sx={{
							height: "5rem",
							width: "100%",
							backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
							borderRadius: "8px 8px 0px 0px",
							boxShadow: "0px -2px 8px 0px rgba(0, 0, 0, 0.10)",
							gap: "1rem",
							justifyContent: "center",
						}}
					>
						{!search_filter_state.business_profile_type.is_applied && (
							<BeegruButton
								flavor="primary"
								variant="text"
								size="large"
								sx={{
									fontSize: "0.875rem",
									fontWeight: 400,
									alignItems: "center",
									height: "fit-content",
									padding: "0.35rem 0.rem 0.35rem 0.5rem",
									width: "40%",
								}}
								onClick={() => {
									ClearAllFilters();
								}}
							>
								{router.locale === "ar-AE" ? "إعادة تعيين" : router.locale === "kn-IN" ? "ಮರುಹೊಂದಿಸಿ" : "Reset"}
							</BeegruButton>
						)}
						<BeegruButton
							flavor="primary"
							variant="contained"
							size="large"
							sx={{
								fontSize: "0.875rem",
								fontWeight: 400,
								padding: "0.35rem 0.rem 0.35rem 0.5rem",
								width: "60%",
							}}
							onClick={handleClickSearchButton}
						>
							{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
						</BeegruButton>
					</ButtonCompoent>
				) : null}
			</CustomSwipeableDrawer>
			{/* </Container> */}

			{/* Location & Quick Filters */}

			<SearchResultsContainer
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				<ContainerNewFilters
					sx={{
						display: searchanimationsettings.StartAnimate ? "flex" : "none",
						flexDirection: "column",
						[theme.breakpoints.down("sm")]: {
							padding: "0rem 0rem 0rem 1rem",
						},
					}}
				>
					{/* Location */}

					<StickyLocationAndFilterContainer>
						<LocationContainer
							onClick={() => {
								dispatch(
									setSearchAnimationDetailsThunk({
										StartAnimate: searchanimationsettings.StartAnimate,
										TriggerSearch: searchanimationsettings.TriggerSearch,
										ResetSearch: false,
										PropertySubCategory: searchanimationsettings.PropertySubCategory,
										HomeAccordion: searchanimationsettings.HomeAccordion,
										OpenLocationDrawer: true,
									}),
								);
							}}
						>
							<LocationOnOutlined
								sx={{
									color: theme.palette.primary.main,
								}}
							/>

							<Typography
								variant="subtitle2"
								sx={{
									fontSize: "14px",
									fontWeight: 500,
									color: theme.palette.text.primary,
								}}
							>
								{locationTextValue}
							</Typography>
						</LocationContainer>

						<IconButton
							onClick={() => setTrigger(true)}
							sx={{
								background: "#ffebda",
								borderRadius: "0.75rem",
								padding: "0.5rem",
								border: "#FAFAFA",
							}}
						>
							<FilterAltOutlined
								sx={{
									color: "#6e3037",
								}}
							/>
						</IconButton>
					</StickyLocationAndFilterContainer>

					{/* Quick Filters */}

					<Stack
						sx={{
							display: "flex",
							flexDirection: "row",
							width: "100%",
							zIndex: 6,
							gap: "0.5rem",
							height: "100%",
							margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.6rem" : "0rem 0rem 0rem 0rem",
							background: "transparent",
							padding: "1rem 0rem 0.5rem 0rem",
							[theme.breakpoints.down("sm")]: {
								background: theme.palette.background.paper,
							},
							"&::-webkit-scrollbar": {
								display: "none",
							},
						}}
					>
						<Badge
							badgeContent={badge}
							color="primary"
							variant="standard"
						>
							<FilterChip
								disableRipple
								onClick={(e: any) => {
									DrawerOpen();
									e.stopPropagation();
								}}
								sx={{
									height: "2rem",
									border: badge !== 0 ? "1px solid rgba(252, 128, 25, 0.48)" : "1px solid rgba(0, 0, 0, 0.12)",
									background: badge !== 0 ? "rgba(252, 128, 25, 0.16)" : theme.palette.background.paper,
								}}
							>
								<TuneIcon
									sx={{
										fontSize: "1rem",
									}}
								/>
							</FilterChip>
						</Badge>

						{/* Type and budget filters */}
						<OverflowQuickfiltersContainer>
							{filterTypes.map((data: any, id: any) =>
								data === null ? null : (
									<PropertyChips
										key={id}
										sx={{
											height: "2rem",
											...getStyles(data, id, search_filter_state, filterTypes.length),
											"& .MuiChip-label": {
												fontSize: "14px",
												fontWeight: getStyles(data, id, search_filter_state, filterTypes.length).fontWeight,
											},
										}}
										label={
											data === "Property type"
												? search_filter_state.property_category.selected_options.length > 0 ||
													search_filter_state.property_type.selected_options.length > 0
													? `${search_filter_state.property_category.selected_options[0]?.title}${
															search_filter_state.property_type.selected_options.length > 0
																? ` - ${search_filter_state.property_type.selected_options[0]?.title}`
																: ""
														}`
													: router.locale === "ar-AE"
														? "نوع العقار"
														: router.locale === "kn-IN"
															? "ಆಸ್ತಿ ಪ್ರಕಾರ"
															: data
												: data === "Service type"
													? search_filter_state.service_type.selected_options.length > 0 ||
														search_filter_state.service_subtype.selected_options.length > 0
														? `${search_filter_state.service_type.selected_options[0]?.title}${
																search_filter_state.service_subtype.selected_options.length > 0
																	? ` - ${search_filter_state.service_subtype.selected_options[0]?.title}`
																	: ""
															}`
														: router.locale === "ar-AE"
															? "نوع الخدمة"
															: router.locale === "kn-IN"
																? "ಸೇವೆಯ ರೀತಿ"
																: data
													: data === "Business user type"
														? search_filter_state.business_profile_type.selected_options.length > 0 &&
															search_filter_state.business_profile_type.selected_options[0]?.title === "Landowners"
															? "Owners"
															: search_filter_state.business_profile_type.selected_options.length > 0 &&
																  search_filter_state.business_profile_type.selected_options[0]?.title ===
																		"Professionals"
																? "Service Professionals"
																: search_filter_state.business_profile_type.selected_options.length > 0
																	? `${search_filter_state.business_profile_type.selected_options[0]?.title}`
																	: router.locale === "ar-AE"
																		? "نوع مستخدم الأعمال"
																		: router.locale === "kn-IN"
																			? "ವ್ಯವಸಾಯ ಬಳಕೆದಾರರ ರೀತಿ"
																			: data
														: data === "Budget"
															? search_filter_state.budget.is_applied
																? `₹${PreparePriceString(budgetToDisplay.min)}  ${budgetToDisplay.max === 0 ? "- Max limit" : `- ₹${PreparePriceString(budgetToDisplay.max)}`}`
																: router.locale === "ar-AE"
																	? "السعر"
																	: router.locale === "kn-IN"
																		? "ಬಜೆಟ್"
																		: data
															: data === "Buy"
																? router.locale === "ar-AE"
																	? "للبيع"
																	: router.locale === "kn-IN"
																		? "ಮಾರಾಟಕ್ಕೆ"
																		: "For Sale"
																: data === "Rent"
																	? router.locale === "ar-AE"
																		? "للإيجار"
																		: router.locale === "kn-IN"
																			? "ಬಾಡಿಗೆಗೆ"
																			: "For Rent"
																	: data === "Resale"
																		? router.locale === "ar-AE"
																			? "إعادة البيع"
																			: router.locale === "kn-IN"
																				? "ಮರುಮಾರಾಟ"
																				: "For Resale"
																		: data
										}
										variant="outlined"
										onDelete={() => {
											setBottomDrawerKey(id);
											filterTypesHandleClick(data);
										}}
										deleteIcon={
											filterType === data && openBottomDrawerFilters ? (
												<KeyboardArrowUpIcon />
											) : (
												<KeyboardArrowDownIcon />
											)
										}
										onClick={() => {
											setBottomDrawerKey(id);
											filterTypesHandleClick(data);
										}}
									/>
								),
							)}
						</OverflowQuickfiltersContainer>
					</Stack>

					<CustomSwipeableDrawer
						anchor="bottom"
						open={openBottomDrawerFilters}
						onClose={() => setOpenBottomDrawerFilters(false)}
						onOpen={() => setOpenBottomDrawerFilters(true)}
						swipeAreaWidth={156}
						disableSwipeToOpen={true}
						ModalProps={{
							keepMounted: true,
						}}
						sx={{
							"& .MuiDrawer-paper": {
								background: theme.palette.background.paper,
								padding: "1rem",
								height: "auto",
								direction: router.locale === "ar-AE" ? "rtl" : "ltr",
							},
						}}
					>
						{DrawerRenderFunctions(bottomDrawerKey)}
					</CustomSwipeableDrawer>
					{/* </div> */}
				</ContainerNewFilters>
				{/* </Slide> */}

				{search_api_response && search_api_response?.total_no_of_results !== 0 ? (
					<Typography
						variant="body2"
						sx={{
							fontSize: "0.875rem",
							fontWeight: 500,
							color: theme.palette.text.secondary,
							lineHeight: "1rem",
							margin: "0.5rem 0rem 0.25rem 1rem",
							[theme.breakpoints.down("sm")]: {
								paddingTop: "6.5rem",
							},
						}}
					>
						{router.locale === "ar-AE"
							? // Arabic
								`${
									search_filter_state.search_type.selected_options[0]?.title === "Properties"
										? "عقارات"
										: search_filter_state.search_type.selected_options[0]?.title === "Services"
											? "خدمات"
											: search_filter_state.search_type.selected_options[0]?.title === "Business Profiles"
												? "ملفات الأعمال"
												: search_filter_state.search_type.selected_options[0]?.title === "Users"
													? "المستخدمين"
													: search_filter_state.search_type.selected_options[0]?.title.toLowerCase()
								} ${
									search_filter_state.search_type.selected_options[0]?.title === "Properties"
										? search_filter_state.transaction_type.selected_options[0]?.title === "Buy"
											? "للبيع"
											: search_filter_state.transaction_type.selected_options[0]?.title === "Rent"
												? "للإيجار"
												: ""
										: ""
								}`
							: router.locale === "kn-IN"
								? // Kannada
									`${
										search_filter_state.search_type.selected_options[0]?.title === "Properties"
											? "ಆಸ್ತಿಗಳು"
											: search_filter_state.search_type.selected_options[0]?.title === "Services"
												? "ಸೇವೆಗಳು"
												: search_filter_state.search_type.selected_options[0]?.title === "Business Profiles"
													? "ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್‌ಗಳು"
													: search_filter_state.search_type.selected_options[0]?.title === "Users"
														? "ಬಳಕೆದಾರರು"
														: search_filter_state.search_type.selected_options[0]?.title.toLowerCase()
									} ${
										search_filter_state.search_type.selected_options[0]?.title === "Properties"
											? search_filter_state.transaction_type.selected_options[0]?.title === "Buy"
												? "ಮಾರಾಟಕ್ಕೆ"
												: search_filter_state.transaction_type.selected_options[0]?.title === "Rent"
													? "ಭಾಡೆಗೆ"
													: ""
											: ""
									}`
								: // English

									`Showing ${search_filter_state.search_type.selected_options[0]?.title.toLowerCase()}
					${
						search_filter_state.search_type.selected_options[0]?.title === "Properties"
							? `to
					${search_filter_state.transaction_type.selected_options[0]?.title.toLowerCase()}`
							: ""
					}`}
					</Typography>
				) : (
					<div
						style={{
							paddingTop: "4.5rem",
						}}
					/>
				)}
			</SearchResultsContainer>
		</React.Fragment>
	);
};

export default Search;

{
	/* Location */
}

{
	/* <TextField
							variant="outlined"
							fullWidth
							size="small"
							value={locationTextValue}
							// disabled
							onClick={() => {
								dispatch(
									setSearchAnimationDetailsThunk({
										StartAnimate: searchanimationsettings.StartAnimate,
										TriggerSearch: searchanimationsettings.TriggerSearch,
										ResetSearch: false,
										PropertySubCategory: searchanimationsettings.PropertySubCategory,
										HomeAccordion: searchanimationsettings.HomeAccordion,
										OpenLocationDrawer: true,
									}),
								);
							}}
							slotProps={{
								input: {
									startAdornment: (
										<InputAdornment position="start">
											<LocationOnOutlined
												sx={{
													color: theme.palette.primary.main,
												}}
											/>
										</InputAdornment>
									),
								},
							}}
							sx={{
								"& .MuiInputBase-input.Mui-disabled": {
									WebkitTextFillColor: "#000000",
								},
							}}
						/> */
}

{
	/* <LocationMainContainer
						onClick={() => {
							dispatch(
								setSearchAnimationDetailsThunk({
									StartAnimate: searchanimationsettings.StartAnimate,
									TriggerSearch: searchanimationsettings.TriggerSearch,
									ResetSearch: false,
									PropertySubCategory: searchanimationsettings.PropertySubCategory,
									HomeAccordion: searchanimationsettings.HomeAccordion,
									OpenLocationDrawer: true,
								}),
							);
						}}
					>
						<LocationContainer>
							<HeaderLocationIconContainer>
								<LocationOnOutlinedIcon
									sx={{
										width: "1.25rem",
										height: "1.25rem",
										color: "#FC8019",
										padding: "0rem",
									}}
								/>
							</HeaderLocationIconContainer>

							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Typography
									variant="subtitle2"
									sx={{
										fontSize: "14px",
										fontWeight: 500,
										color: theme.palette.text.primary,
									}}
								>
									{locationTextValue}
								</Typography>
								<Typography
									sx={{
										fontSize: "0.625rem",
										color: "#FC8019",
									}}
								>
									{router.locale === "ar-AE"
										? "نوع العقار"
										: router.locale === "kn-IN"
											? "ಆಸ್ತಿ ಪ್ರಕಾರ"
											: "Change location"}
								</Typography>
							</div>
						</LocationContainer>
						<HeaderLocationIconContainer>
							<KeyboardArrowDownIcon
								sx={{
									width: "1.25rem",
									height: "1.25rem",
									color: "#FC8019",
								}}
							/>
						</HeaderLocationIconContainer>
					</LocationMainContainer> */
}

{
	/* <FilterChip
							onClick={(e: any) => {
								dispatch(
									setSearchAnimationDetailsThunk({
										StartAnimate: searchanimationsettings.StartAnimate,
										TriggerSearch: searchanimationsettings.TriggerSearch,
										ResetSearch: false,
										PropertySubCategory: searchanimationsettings.PropertySubCategory,
										HomeAccordion: searchanimationsettings.HomeAccordion,
										OpenLocationDrawer: true,
									}),
								);
								e.stopPropagation();
							}}
							sx={{
								height: "2rem",
								border:
									search_filter_state.location.is_applied === true
										? "1px solid rgba(252, 128, 25, 0.48)"
										: "1px solid rgba(0, 0, 0, 0.12)",
								background:
									search_filter_state.location.is_applied === true
										? "rgba(252, 128, 25, 0.16)"
										: theme.palette.background.paper,
								[theme.breakpoints.down("sm")]: {
									padding: router.locale === "ar-AE" ? "0rem 0.25rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0.25rem",
								},
							}}
						>
							<FmdGoodOutlined
								sx={{
									fontSize: "1rem",
									color: theme.palette.primary.main,
								}}
							/>

							<Typography
								variant="body2"
								sx={{
									fontSize: "0.825rem",
								}}
							>
								{search_filter_state.location.selected_options.length > 0
									? typeof search_filter_state.location.selected_options[0].value === "object" &&
										"title" in search_filter_state.location.selected_options[0].value
										? search_filter_state.location.selected_options[0].value.title
										: ""
									: router.locale === "ar-AE"
										? "الموقع"
										: router.locale === "kn-IN"
											? "ಸ್ಥಳ"
											: "Location"}
							</Typography>
						</FilterChip> */
}
