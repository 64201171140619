/* Common imports */
import React from "react";

/* mui imports */
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import { Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../redux-magic/thunks";

import {
	cleaning_and_maintenance_options_active,
	construction_options_active,
	food_options_active,
	grooming_and_other_personal_care_options_active,
	home_and_appliance_repair_options_active,
	home_utilities_options_active,
	hospitality_options_active,
	interiors_and_decor_options_active,
	legal_and_finance_options_active,
	liaisoning_options_active,
	other_options_active,
	social_service_options_active,
	transport_options_active,
} from "../../lib/data/search-filters/_x_service_subtype";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));
const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const CustomHomeWorkOutlinedIcon = styled(HomeWorkOutlinedIcon)(({ theme }) => ({
	margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

export const _w_service_type = ({
	search_id,
	service_category_value,
	service_type,
	dispatch,
}: {
	search_id: SearchFilterId;
	service_category_value: string;
	service_type: StateInterface["search_filters_state"]["service_type"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const router = useRouter();

	const [slide, setSlide] = React.useState<boolean>(service_type?.is_accordion_open);

	const handleUpdateServiceType = (value: Array<SearchFilterOptionTextActive>) => {
		const selectedOptionId = value[0]?.id;

		let serviceTypeAffects = service_type?.affects[0];

		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: service_type.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);

		dispatch(updateSearchFilterIsAccordionOpenThunk({ searchFilterId: serviceTypeAffects, isAccordionOpen: true }));

		const serviceSubTypeOptions =
			selectedOptionId === "construction"
				? construction_options_active
				: selectedOptionId === "interiors_and_decor"
					? interiors_and_decor_options_active
					: selectedOptionId === "cleaning_and_maintenance"
						? cleaning_and_maintenance_options_active
						: selectedOptionId === "home_utilities"
							? home_utilities_options_active
							: selectedOptionId === "home_and_appliance_repair"
								? home_and_appliance_repair_options_active
								: selectedOptionId === "legal_and_finance"
									? legal_and_finance_options_active
									: selectedOptionId === "liaisoning"
										? liaisoning_options_active
										: selectedOptionId === "transport"
											? transport_options_active
											: selectedOptionId === "grooming_and_other_personal_care"
												? grooming_and_other_personal_care_options_active
												: selectedOptionId === "social_service"
													? social_service_options_active
													: selectedOptionId === "food"
														? food_options_active
														: selectedOptionId === "hospitality"
															? hospitality_options_active
															: selectedOptionId === "other"
																? other_options_active
																: construction_options_active;

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: service_type ? (service_type.affects[0] as SearchFilterId) : ("" as SearchFilterId),
				newOptions: serviceSubTypeOptions,
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the service_type is selected
		let isServiceTypeSelected = service_type ? (service_type.selected_options.length !== 0 ? true : false) : false;

		// lets update the the applied filter to true is data exists
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isServiceTypeSelected }));

		// lets check if the service_type affects any other filter
		let serviceTypeAffects = service_type ? (service_type.affects[0] as SearchFilterId) : ("" as SearchFilterId);

		// lets update the visibility of the affected filter
		dispatch(
			updateSearchFilterVisibilityThunk({ searchFilterId: serviceTypeAffects, isVisible: isServiceTypeSelected }),
		);
	}, [service_type, dispatch, search_id]);

	return (
		<Container>
			<HeaderContainer>
				<CustomHomeWorkOutlinedIcon />
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "نوع الخدمة" : router.locale === "kn-IN" ? "ಸೇವೆಯ ರೀತಿ" : "Service type"}
				</Heading>
				{/* {service_type?.selected_options.length != 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CheckBoxContainer>
				{service_type?.active_options
					.filter((gg) =>
						[
							"construction",
							"transport",
							"interiors_and_decor",
							"home_and_appliance_repair",
							"cleaning_and_maintenance",
						].includes(gg.id),
					)
					.map((item: SearchFilterOptionTextActive) => (
						<CheckBoxDiv
							disableRipple
							disableTouchRipple
							key={item.id}
							onClick={() => {
								const isSelected = item.id === service_type?.selected_options[0]?.id;
								isSelected
									? handleUpdateServiceType([] as Array<SearchFilterOptionTextActive>)
									: handleUpdateServiceType([item] as Array<SearchFilterOptionTextActive>);
							}}
							sx={{
								background: item.id === service_type?.selected_options[0]?.id ? "rgba(252, 128, 25, 0.16)" : "",
								border: item.id === service_type?.selected_options[0]?.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
							}}
						>
							{/* {item.id === service_type?.selected_options[0]?.id ? (
									<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
								) : (
									<AddIcon />
								)} */}
							<Text
								variant="body1"
								sx={{
									fontWeight: item?.id === service_type?.selected_options[0]?.id ? "500" : "400",
								}}
							>
								{item.title}
							</Text>
						</CheckBoxDiv>
					))}
			</CheckBoxContainer>
		</Container>
	);
};
