/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionText,
	SearchFilterOptionValueRange,
} from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/thunks";

/* Component Imports */

import { Button, Divider, Typography } from "@mui/material";
import { useRouter } from "next/router";

/* Icon Imports */

import { Brightness1, EventAvailableOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomEventAvailableOutlinedIcon = styled(EventAvailableOutlined)(({ theme }) => ({
	margin: "0rem 0rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomSelectedIcon = styled(Brightness1)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const ChipsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

const Chip = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	minWidth: "2.25rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

export const _n_property_status = ({
	search_id,
	property_status,
	transaction_type,
	property_type,
	dispatch,
	posession_date,
}: {
	search_id: SearchFilterId;
	property_status: StateInterface["search_filters_state"]["property_status"];
	transaction_type: StateInterface["search_filters_state"]["transaction_type"];
	property_type: StateInterface["search_filters_state"]["property_type"];
	dispatch: Function;
	posession_date: StateInterface["search_filters_state"]["possession_date"];
}) => {
	const router = useRouter();

	const [source, setSource] = React.useState<string>("");

	/* Dates to work with */

	const current_date: number = Date.now();
	const date_after_one_month: number = current_date + 30 * 24 * 60 * 60 * 1000;
	const date_after_one_year: number = current_date + 365 * 24 * 60 * 60 * 1000;

	/* Local variable for selected options */

	const [selectedOptions, setSelectedOptions] = React.useState<Array<SearchFilterOption>>([]);
	// console.log("selectedOptions", selectedOptions);
	/* Property Status Chip Options */

	let options: Array<SearchFilterOptionText> = [];

	switch (transaction_type.selected_options[0]?.id) {
		case "buy":
			switch (property_type.selected_options[0]?.id) {
				case "plot":
					options = [
						{
							id: "ready_for_registration",
							title: "Ready for Registration",
							value: "ready_for_registration",
						},
						{
							id: "less_than_one_year",
							title: "Less than 1 year",
							value: "less_than_one_year",
						},
						{
							id: "more_than_one_year",
							title: "More than 1 year",
							value: "more_than_one_year",
						},
					];
				case "plot_commercial":
					options = [
						{
							id: "ready_for_registration",
							title: "Ready for Registration",
							value: "ready_for_registration",
						},
						{
							id: "less_than_one_year",
							title: "Less than 1 year",
							value: "less_than_one_year",
						},
						{
							id: "more_than_one_year",
							title: "More than 1 year",
							value: "more_than_one_year",
						},
					];
				case "farm_plot":
					options = [
						{
							id: "ready_for_registration",
							title: "Ready for Registration",
							value: "ready_for_registration",
						},
						{
							id: "less_than_one_year",
							title: "Less than 1 year",
							value: "less_than_one_year",
						},
						{
							id: "more_than_one_year",
							title: "More than 1 year",
							value: "more_than_one_year",
						},
					];
					break;
				default:
					options = [
						{
							id: "ready_to_move_in",
							title: "Ready to Move In",
							value: "ready_to_move_in",
						},
						{
							id: "less_than_one_year",
							title: "Less than 1 year",
							value: "less_than_one_year",
						},
						{
							id: "more_than_one_year",
							title: "More than 1 year",
							value: "more_than_one_year",
						},
					];
					break;
			}
			break;
		case "rent":
			options = [
				{
					id: "immediately",
					title: "Immediately",
					value: "immediately",
				},
				{
					id: "less_than_one_month",
					title: "Less than 1 month",
					value: "less_than_one_month",
				},
				{
					id: "more_than_one_month",
					title: "More than 1 month",
					value: "more_than_one_month",
				},
			];
			break;
		default:
			options = [
				{
					id: "ready_to_move_in",
					title: "Ready to Move In",
					value: "ready_to_move_in",
				},
				{
					id: "less_than_one_year",
					title: "Less than 1 year",
					value: "less_than_one_year",
				},
				{
					id: "more_than_one_year",
					title: "More than 1 year",
					value: "more_than_one_year",
				},
			];
			break;
	}

	/* Setting initial values for property status & possession date */

	React.useEffect(() => {
		if (source !== "main_filter") {
			// TO DO: Set selectedOptions state for property status & pass Possession date to this component and set the chip according to that
		}
		const possesionChecker = posession_date.selected_options[0]?.value as SearchFilterOptionValueRange;
		if (posession_date.is_applied && property_status.selected_options[0]?.id === "under_construction") {
			if (!possesionChecker.unlock_max) {
				setSelectedOptions([
					{
						id: "less_than_one_year",
						title: "Less than 1 year",
						value: "less_than_one_year",
					},
				]);
			} else {
				setSelectedOptions([
					{
						id: "more_than_one_year",
						title: "More than 1 year",
						value: "more_than_one_year",
					},
				]);
			}
		} else if (!posession_date.is_applied && property_status.selected_options[0]?.id === "ready_to_move_in") {
			setSelectedOptions([
				{
					id: "ready_to_move_in",
					title: "Ready to Move In",
					value: "ready_to_move_in",
				},
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [property_status, posession_date]);
	// console.log(possession_date, "possession_date");
	/* Function to handle the update of the property status */

	const handleUpdatePropertyStatus = (item: SearchFilterOption) => {
		setSource("main_filter");

		let selected_options = selectedOptions.some((val) => val.id === item.id) ? [] : [item];

		setSelectedOptions(selected_options);

		if (transaction_type.selected_options[0]?.id === "buy") {
			switch (item.id) {
				case "less_than_one_year":
					/* Less than 1 year */

					/* Updating the selected options for the property status filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "property_status",
							newOptions: [
								{
									id: "under_construction",
									title: "Under Construction",
									value: "under_construction",
								},
							],
						}),
					);

					/* Applying the selected options to the property status filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "property_status",
							isApplied: true,
						}),
					);

					/* Updating the selected options for the possession date filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "possession_date",
							newOptions: [
								{
									id: "possession_date",
									title: "Possession Date",
									value: {
										min: current_date,
										max: date_after_one_year,
										unlock_max: false,
									},
								},
							],
						}),
					);

					/* Applying the selected options to the possession date filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "possession_date",
							isApplied: true,
						}),
					);

					break;

				case "more_than_one_year":
					/* More than 1 year */

					/* Updating the selected options for the property status filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "property_status",
							newOptions: [
								{
									id: "under_construction",
									title: "Under Construction",
									value: "under_construction",
								},
							],
						}),
					);

					/* Applying the selected options to the property status filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "property_status",
							isApplied: true,
						}),
					);

					/* Updating the selected options for the possession date filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "possession_date",
							newOptions: [
								{
									id: "possession_date",
									title: "Possession Date",
									value: {
										min: date_after_one_year,
										max: date_after_one_year,
										unlock_max: true,
									},
								},
							],
						}),
					);

					/* Applying the selected options to the possession date filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "possession_date",
							isApplied: true,
						}),
					);

					break;

				default:
					/* Ready to Move / Ready for Registration */

					/* Updating the selected options for the property status filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "property_status",
							newOptions: selected_options,
						}),
					);

					/* Applying the selected options to the property status filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "property_status",
							isApplied: true,
						}),
					);

					/* Set possession date filter's isApplied to false */
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "possession_date",
							newOptions: [],
						}),
					);
					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "possession_date",
							isApplied: false,
						}),
					);

					break;
			}
		} else if (transaction_type.selected_options[0]?.id === "rent") {
			switch (item.id) {
				case "less_than_one_month":
					/* Less than 1 month */

					/* Updating the selected options for the property status filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "property_status",
							newOptions: [
								{
									id: "select_date",
									title: "Select Date",
									value: "select_date",
								},
							],
						}),
					);

					/* Applying the selected options to the property status filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "property_status",
							isApplied: true,
						}),
					);

					/* Updating the selected options for the possession date filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "possession_date",
							newOptions: [
								{
									id: "possession_date",
									title: "Possession Date",
									value: {
										min: current_date,
										max: date_after_one_month,
										unlock_max: false,
									},
								},
							],
						}),
					);

					/* Applying the selected options to the possession date filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "possession_date",
							isApplied: true,
						}),
					);

					break;

				case "more_than_one_month":
					/* More than 1 month */

					/* Updating the selected options for the property status filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "property_status",
							newOptions: [
								{
									id: "select_date",
									title: "Select Date",
									value: "select_date",
								},
							],
						}),
					);

					/* Applying the selected options to the property status filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "property_status",
							isApplied: true,
						}),
					);

					/* Updating the selected options for the possession date filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "possession_date",
							newOptions: [
								{
									id: "possession_date",
									title: "Possession Date",
									value: {
										min: date_after_one_month,
										max: date_after_one_month,
										unlock_max: true,
									},
								},
							],
						}),
					);

					/* Applying the selected options to the possession date filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "possession_date",
							isApplied: true,
						}),
					);

					break;

				default:
					/* Immediately */

					/* Updating the selected options for the property status filter */

					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "property_status",
							newOptions: selected_options,
						}),
					);

					/* Applying the selected options to the property status filter */

					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "property_status",
							isApplied: true,
						}),
					);

					/* Set possession date filter's isApplied to false and empty the state */
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "possession_date",
							newOptions: [],
						}),
					);
					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: "possession_date",
							isApplied: false,
						}),
					);
					break;
			}
		}

		setSource("");
	};

	// React.useEffect(() => {
	// 	// lets check if the property status is selected
	// 	let isPropertyStatusSelected = property_status
	// 		? property_status.selected_options.length !== 0
	// 			? true
	// 			: false
	// 		: false;

	// 	// lets update the isApplied value if the property status is selected
	// 	dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isPropertyStatusSelected }));

	// 	// lets set visibility of the property status affects
	// 	property_status.affects.map((item: SearchFilterId) => {
	// 		switch (transaction_type.selected_options[0]?.id) {
	// 			case "buy":
	// 				switch (property_status.selected_options[0]?.id) {
	// 					case "under_construction":
	// 						switch (item) {
	// 							case "possession_date":
	// 								dispatch(
	// 									updateSearchFilterVisibilityThunk({
	// 										searchFilterId: item,
	// 										isVisible: true,
	// 									}),
	// 								);
	// 								break;
	// 							case "available_from":
	// 								dispatch(
	// 									updateSearchFilterVisibilityThunk({
	// 										searchFilterId: item,
	// 										isVisible: false,
	// 									}),
	// 								);
	// 								break;
	// 						}
	// 						break;
	// 					default:
	// 						dispatch(
	// 							updateSearchFilterVisibilityThunk({
	// 								searchFilterId: item,
	// 								isVisible: false,
	// 							}),
	// 						);
	// 						break;
	// 				}
	// 				break;
	// 			case "rent":
	// 				switch (property_status.selected_options[0]?.id) {
	// 					case "select_date":
	// 						switch (item) {
	// 							case "possession_date":
	// 								dispatch(
	// 									updateSearchFilterVisibilityThunk({
	// 										searchFilterId: item,
	// 										isVisible: false,
	// 									}),
	// 								);
	// 								break;
	// 							case "available_from":
	// 								dispatch(
	// 									updateSearchFilterVisibilityThunk({
	// 										searchFilterId: item,
	// 										isVisible: true,
	// 									}),
	// 								);
	// 								break;
	// 						}
	// 						break;
	// 					default:
	// 						dispatch(
	// 							updateSearchFilterVisibilityThunk({
	// 								searchFilterId: item,
	// 								isVisible: false,
	// 							}),
	// 						);
	// 						break;
	// 				}
	// 				break;
	// 			default:
	// 				dispatch(
	// 					updateSearchFilterVisibilityThunk({
	// 						searchFilterId: item,
	// 						isVisible: true,
	// 					}),
	// 				);

	// 				break;
	// 		}

	// 		if (!isPropertyStatusSelected) {
	// 			dispatch(
	// 				updateSearchFilterSelectedOptionsThunk({
	// 					searchFilterId: item,
	// 					newOptions: [],
	// 				}),
	// 			);
	// 		}
	// 	});
	// }, [property_status, search_id, dispatch, transaction_type, property_type]);

	return (
		<Container>
			<HeaderContainer>
				<CustomEventAvailableOutlinedIcon
				// sx={{
				// 	margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
				// }}
				/>
				<Heading variant="body2">
					{transaction_type.selected_options[0]?.id === "rent"
						? router.locale === "ar-AE"
							? ""
							: router.locale === "kn-IN"
								? "ಲಭ್ಯವಿರುವ ದಿನಾಂಕ"
								: "Available from"
						: router.locale === "ar-AE"
							? "التوافر"
							: router.locale === "kn-IN"
								? "ಥಿತಿ"
								: "Possession Status"}
				</Heading>
				{selectedOptions.length != 0 && <CustomSelectedIcon />}
			</HeaderContainer>

			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<ChipsContainer>
				{options.map((item: SearchFilterOptionText) => {
					const selected = item.id === selectedOptions[0]?.id;

					return (
						<Chip
							disableRipple
							disableTouchRipple
							key={item.id}
							onClick={() => {
								handleUpdatePropertyStatus(item);
							}}
							sx={{
								background: selected ? "#FC801933" : "",
								border: selected ? "1px solid #FC8019" : "1px solid #C0C0C0",
							}}
						>
							<Text
								variant="body1"
								sx={{
									fontWeight: selected ? 500 : 400,
								}}
							>
								{item.title}
							</Text>
						</Chip>
					);
				})}
			</ChipsContainer>
		</Container>
	);
};
