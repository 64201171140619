import { styled } from "@mui/system";

import { Skeleton } from "@mui/material";
import PropertyLoadingCardMini from "./PropertyLoadingCardMiniTwo";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
}));

const HeaderDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const ChipsRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "0.5rem 0rem 0.5rem 0rem",
}));

const PropertyCardDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
	rowGap: "0.75rem",
	flexWrap: "wrap",
	margin: "0.5rem 0rem 0rem 0rem",
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		// gap: "0.5rem", // 3 cards
		// rowGap: "0.75rem", //3 cards
		gap: "0.75rem", // 2 cards
		rowGap: "0.75rem", // 2cards
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		// gap: "0.5rem", // 3 cards
		// rowGap: "0.75rem", //3 cards
		gap: "1rem", // 2 cards
		rowGap: "1rem", // 2 cards
	},
}));

const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

const SearchMobileLayoutSkeleton = () => {
	return (
		<MainContainer>
			<HeaderDiv>
				<ChipsRow sx={{ margin: "0rem 0rem 0rem 0rem", gap: "0.5rem" }}>
					<Skeleton
						animation="wave"
						variant="text"
						width="88%"
						height="4.5rem"
						sx={{ borderRadius: "0.5rem" }}
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="10%"
						height="4.5rem"
						sx={{ borderRadius: "0.5rem" }}
					/>
					{/* <HeaderDiv>
						<Skeleton
							animation="wave"
							variant="text"
							width="40%"
							height="2rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="30%"
							height="1rem"
						/>
					</HeaderDiv> */}
				</ChipsRow>

				<ChipsRow sx={{ margin: "0rem 0rem 0.75rem 0.25rem" }}>
					<Skeleton
						animation="wave"
						variant="text"
						width="1.5rem"
						height="2.75rem"
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="6rem"
						height="2.75rem"
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="5rem"
						height="2.75rem"
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="8rem"
						height="2.75rem"
					/>
				</ChipsRow>
			</HeaderDiv>

			<Skeleton
				animation="wave"
				variant="text"
				width="70%"
				height="2rem"
			/>

			<PropertyCardDiv>
				{data.map((data: any, index: number) => (
					<PropertyLoadingCardMini key={index} />
				))}
			</PropertyCardDiv>
		</MainContainer>
	);
};

export default SearchMobileLayoutSkeleton;
