/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOption } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/thunks";

/* Component Imports */

import { Button, Divider, Typography } from "@mui/material";
import { useRouter } from "next/router";

/* Icon Imports */

import { Brightness1, PoolOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomPoolOutlinedIcon = styled(PoolOutlined)(({ theme }) => ({
	margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomSelectedIcon = styled(Brightness1)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const ChipsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

const Chip = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	minWidth: "2.25rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	width: "100%",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

export const _u_amenities = ({
	search_id,
	amenities,
	dispatch,
}: {
	search_id: SearchFilterId;
	amenities: StateInterface["search_filters_state"]["amenities"];
	dispatch: Function;
}) => {
	const router = useRouter();

	const [source, setSource] = React.useState<string>("");

	const [selectedAmenities, setSelectedAmenities] = React.useState<Array<SearchFilterOption>>([]);

	/* Setting initial values for amenities */

	React.useEffect(() => {
		if (source !== "main_filter") {
			setSelectedAmenities(amenities.selected_options);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amenities]);

	const handleUpdateAmenities = (item: SearchFilterOption) => {
		setSource("main_filter");

		const selected_options = selectedAmenities.some((val) => val.id === item.id)
			? selectedAmenities.filter((val) => val.id !== item.id)
			: [...selectedAmenities, item];

		setSelectedAmenities(selected_options);

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);

		setSource("");
	};

	const amenity_chips = [
		{
			id: "swimming-pool",
			title: "Swimming Pool",
			value: "swimming-pool",
		},
		{
			id: "clubhouse",
			title: "Clubhouse",
			value: "clubhouse",
		},
		{
			id: "security",
			title: "Security",
			value: "security",
		},
		{
			id: "gym",
			title: "Gym",
			value: "gym",
		},
		{
			id: "kids-play-area",
			title: "Kids Play Area",
			value: "kids-play-area",
		},
		{
			id: "lift",
			title: "Lift",
			value: "lift",
		},
		{
			id: "car-parking",
			title: "Car Parking",
			value: "car-parking",
		},
		{
			id: "multipurpose-hall",
			title: "Multipurpose Hall",
			value: "multipurpose-hall",
		},
		{
			id: "power-backup",
			title: "Power Backup",
			value: "power-backup",
		},
	];

	return (
		<Container>
			<HeaderContainer>
				<CustomPoolOutlinedIcon
				// sx={{
				// 	margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
				// }}
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE"
						? "المرافق"
						: router.locale === "ar-AE"
							? "عدد الحمامات"
							: router.locale === "kn-IN"
								? "ಸೌಲಭ್ಯಗಳು"
								: "Amenities"}
				</Heading>
				{selectedAmenities.length > 0 && <CustomSelectedIcon />}
			</HeaderContainer>

			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<ChipsContainer>
				{amenity_chips.map((item: any) => {
					const selected = selectedAmenities.some((it) => it.id === item.id);

					return (
						<Chip
							disableRipple
							disableTouchRipple
							key={item.id}
							onClick={() => {
								handleUpdateAmenities(item);
							}}
							sx={{
								background: selected ? "#FC801933" : "",
								border: selected ? "1px solid #FC8019" : "1px solid #C0C0C0",
							}}
						>
							<Text
								variant="body1"
								sx={{
									fontWeight: selected ? 500 : 400,
								}}
							>
								{item.title}
							</Text>
						</Chip>
					);
				})}
			</ChipsContainer>
		</Container>
	);
};
