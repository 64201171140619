/* Common imports */

import React from "react";

/* mui imports */

import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import { updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { SearchFilterId } from "../../redux-magic/sub-interfaces/search-types";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomCategoryIcon = styled(CategoryOutlinedIcon)(({ theme }) => ({
	// margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	margin: "none",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

export const _c_purchase_type = ({ search_id, dispatch }: { search_id: SearchFilterId; dispatch: Function }) => {
	const theme = useTheme();
	const router = useRouter();

	// const handleUpdatePropertyCategory = (value: Array<SearchFilterOptionTextActive>) => {};

	const purchaseOptions = ["Resale", "New Projects"];

	const [purchaseType, setPurchaseType] = React.useState<string[]>([]);

	const handleSelect = (item: string) => {
		setPurchaseType((prev) => (prev.includes(item) ? prev.filter((type) => type !== item) : [item]));
	};

	React.useEffect(() => {
		const dispatchTransactionTypeUpdate = (id: string, title: string, value: string) => {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: search_id,
					newOptions: [
						{
							id,
							title,
							value,
						},
					],
				}),
			);
		};

		if (purchaseType.length === 1) {
			if (purchaseType[0] === "Resale") {
				dispatchTransactionTypeUpdate("resale", "Resale", "resale");
			} else if (purchaseType[0] === "New Projects") {
				dispatchTransactionTypeUpdate("buy", "Buy", "buy");
			} else null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [purchaseType]);

	return (
		<Container>
			<HeaderContainer>
				<CustomCategoryIcon />
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "نوع الشراء" : router.locale === "kn-IN" ? "ಖರೀದಿ ಪ್ರಕಾರ" : "Purchase type"}
				</Heading>
				{/* {property_category.is_applied && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			{/* <Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			> */}
			<CheckBoxContainer>
				{/* {purchaseType.map((item) => (
					<CheckBoxDiv
						key={item.id}
						onClick={() => {
							const isSelected = item.id === property_category?.selected_options[0]?.id;
							isSelected
								? handleUpdatePropertyCategory([] as Array<SearchFilterOptionTextActive>)
								: handleUpdatePropertyCategory([item] as Array<SearchFilterOptionTextActive>);
						}}
						sx={{
							background: item.id === property_category?.selected_options[0]?.id ? "rgba(252, 128, 25, 0.16)" : "",
							border:
								item?.id === property_category?.selected_options[0]?.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
							fontWeight: item?.id === property_category?.selected_options[0]?.id ? "500" : "400",
						}}
					>
						<Text
							variant="body1"
							sx={{
								fontWeight: item?.id === property_category?.selected_options[0]?.id ? "500" : "400",
							}}
						>
							{item.title}
						</Text>
					</CheckBoxDiv>
				))} */}
				{purchaseOptions.map((item) => (
					<CheckBoxDiv
						disableRipple
						disableTouchRipple
						key={item}
						onClick={() => {
							purchaseType.includes(item) ? handleSelect(item) : handleSelect(item);
						}}
						sx={{
							background: purchaseType.includes(item) ? "rgba(252, 128, 25, 0.16)" : "",
							border: purchaseType.includes(item) ? "1px solid #FC8019" : "1px solid #C0C0C0",
							fontWeight: purchaseType.includes(item) ? "500" : "400",
						}}
					>
						<Text
							variant="body1"
							sx={{
								fontWeight: purchaseType.includes(item) ? "500" : "400",
							}}
						>
							{item}
						</Text>
					</CheckBoxDiv>
				))}
			</CheckBoxContainer>
			{/* </Slide> */}
		</Container>
		// </CustomAccordion>
	);
};
