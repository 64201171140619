/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

/* Component Imports */

import { Button, Divider, Typography } from "@mui/material";
import { useRouter } from "next/router";

/* Icon Imports */

import { Brightness1 } from "@mui/icons-material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOptionRange } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/thunks";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	borderRadius: "16px",
	paddingBottom: "1rem",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomSelectedIcon = styled(Brightness1)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	// overflowX: "auto",
	margin: "0rem",
	gap: "0.75rem",
	// "&::-webkit-scrollbar": {
	// 	display: "none",
	// },
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	minWidth: "2.25rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	width: "100%",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

const BedroomsMobile = ({
	search_id,
	dispatch,
	bedrooms,
}: {
	search_id: SearchFilterId;
	bedrooms: StateInterface["search_filters_state"]["bedrooms"];
	dispatch: Function;
}) => {
	const router = useRouter();

	// const [source, setSource] = React.useState<string>("");

	/* Let's create a state to store the selected options */

	const [selectedBedrooms, setSelectedBedrooms] = React.useState<Array<number>>([]);

	// const minBedrooms: number = bedrooms
	// 	? (bedrooms.selected_options[0] as SearchFilterOptionRange)
	// 		? (bedrooms.selected_options[0] as SearchFilterOptionRange).value
	// 			? (bedrooms.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
	// 				? 5
	// 				: (bedrooms.selected_options[0] as SearchFilterOptionRange).value.min
	// 			: 0
	// 		: 0
	// 	: 0;

	// const maxBedrooms: number = bedrooms
	// 	? (bedrooms.selected_options[0] as SearchFilterOptionRange)
	// 		? (bedrooms.selected_options[0] as SearchFilterOptionRange).value
	// 			? (bedrooms.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
	// 				? 5
	// 				: (bedrooms.selected_options[0] as SearchFilterOptionRange).value.max
	// 			: 0
	// 		: 0
	// 	: 0;

	// const [state, setState] = React.useState<Array<number>>(
	// 	minBedrooms === 0 && maxBedrooms === 0 ? [] : [minBedrooms, maxBedrooms],
	// );

	/* Let's create a list of bedroom options */

	const bedroom = [
		{
			id: "studio",
			title: "Studio",
			value: 0,
		},
		{
			id: "1bhk",
			title: "1",
			value: 1,
		},
		{
			id: "2bhk",
			title: "2",
			value: 2,
		},
		{
			id: "3bhk",
			title: "3",
			value: 3,
		},
		{
			id: "4bhk",
			title: "4",
			value: 4,
		},
		{
			id: "4+bhk",
			title: "5+",
			value: 5,
		},
	];

	/* Setting initial values for bedrooms */

	React.useEffect(() => {
		if (bedrooms && bedrooms.selected_options.length !== 0 && bedrooms.is_applied) {
			// Extract min & max from selected options if it is not empty and update the state with everything in between min & max

			const min =
				(bedrooms.selected_options[0] as SearchFilterOptionRange) &&
				(bedrooms.selected_options[0] as SearchFilterOptionRange).value.min;
			const max =
				(bedrooms.selected_options[0] as SearchFilterOptionRange) &&
				(bedrooms.selected_options[0] as SearchFilterOptionRange).value.max;

			setSelectedBedrooms(
				bedrooms.selected_options.length === 0 ? [] : [...Array(max - min + 1).keys()].map((_, index) => min + index),
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bedrooms]);

	const handleBedroomClick = (value: number) => {
		// setSource("main_filter");

		const selected_options = selectedBedrooms.includes(value)
			? selectedBedrooms.filter((val) => val !== value)
			: [...selectedBedrooms, value].sort((a, b) => a - b);

		setSelectedBedrooms(selected_options);

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					selected_options.length === 0
						? []
						: [
								{
									id: "bedrooms",
									title: "Bedrooms",
									value: {
										min: selected_options[0],
										max: selected_options[selected_options.length - 1],
										unlock_max: selected_options.includes(5) ? true : false,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);
	};

	return (
		<Container>
			<HeaderContainer>
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "عدد الغرف" : router.locale === "kn-IN" ? "ಕೋಣೆಗಳು" : "Bedrooms"}
				</Heading>
				{selectedBedrooms.length !== 0 && <CustomSelectedIcon />}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<CheckBoxContainer>
				{bedroom.map((item: any) => {
					const selected = selectedBedrooms.includes(item.value);

					return (
						<CheckBoxDiv
							key={item.id}
							disableRipple
							disableTouchRipple
							onClick={() => {
								handleBedroomClick(item.value);
							}}
							sx={{
								background: selected ? "#FC801933" : "",
								border: selected ? "1px solid #FC8019" : "1px solid #C0C0C0",
							}}
						>
							<Text
								variant="body1"
								sx={{
									fontWeight: selected ? 500 : 400,
								}}
							>
								{item.title}
							</Text>
						</CheckBoxDiv>
					);
				})}
			</CheckBoxContainer>
		</Container>
	);
};

export default BedroomsMobile;
