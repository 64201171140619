/* Common imports */

import React from "react";

/* mui imports */

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Divider,
	Slide,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";

import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "0.5rem 0.75rem 0.5rem 0.75rem",
	margin: "0rem",
	fontSize: "1rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.125rem",
		padding: "1rem 1rem 1rem 1rem",
	},
	"& .MuiAccordionSummary-content": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		margin: "0rem",
		[theme.breakpoints.up("md1190")]: {
			margin: "0.75rem 0rem",
		},
	},
}));

const CustomCategoryIcon = styled(CategoryOutlinedIcon)(({ theme }) => ({
	margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: "#6e3037",
	backgroundColor: "#ffe9d9",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	padding: "0rem 0.75rem 1rem 0.75rem",
	[theme.breakpoints.up("md1190")]: {
		padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: "#6e3037",
}));

const DoneIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
	color: "#6e3037",
	height: "1rem",
	width: "1rem",
}));

const AddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
	color: "#6e3037",
	height: "1rem",
	width: "1rem",
}));

export const _v_service_category = ({
	search_id,
	service_category,
	dispatch,
}: {
	search_id: SearchFilterId;
	service_category: StateInterface["search_filters_state"]["service_category"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const router = useRouter();

	const [slide, setSlide] = React.useState<boolean>(service_category.is_accordion_open);

	const handleUpdateServiceCategory = (value: Array<SearchFilterOptionTextActive>) => {
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		const selectedOptionId = value[0]?.id;

		const updatedActiveOptions = service_category.active_options.map((item) => ({
			...item,
			is_selected: item.id === selectedOptionId,
		}));

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: updatedActiveOptions,
			}),
		);

		if (
			service_category.selected_options.length === 0 ||
			service_category.selected_options[0]?.id !== selectedOptionId
		) {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "service_type",
					newOptions: [],
				}),
			);
		}
	};

	React.useEffect(() => {
		const isApplied = service_category.selected_options.length !== 0;
		const affectsId = service_category.affects?.[0];

		if (affectsId) {
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: affectsId,
					isVisible: isApplied,
				}),
			);
		}

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: isApplied,
			}),
		);

		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: affectsId,
				isAccordionOpen: isApplied,
			}),
		);
	}, [service_category, search_id, dispatch]);

	return (
		<CustomAccordion
			disableGutters={true}
			square={true}
			defaultExpanded={service_category.is_accordion_open as boolean}
			onChange={() => setSlide(!slide)}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomCategoryIcon
					sx={{
						margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
					}}
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "فئة الخدمة" : router.locale === "kn-IN" ? "ಸೇವಾ ವರ್ಗ" : "Service category"}
				</Heading>{" "}
				{service_category.is_applied && <CustomSelectedIcon />}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					{service_category.active_options.map((item: SearchFilterOptionTextActive) => (
						<CheckBoxDiv
							disableRipple
							disableTouchRipple
							key={item.id}
							onClick={() => {
								const isSelected = item.id === service_category?.selected_options[0]?.id;
								isSelected
									? handleUpdateServiceCategory([] as Array<SearchFilterOptionTextActive>)
									: handleUpdateServiceCategory([item] as Array<SearchFilterOptionTextActive>);
							}}
							sx={{
								background: item.is_selected ? "#FC801933" : "",
								border: item.is_selected
									? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
									: "1px solid #C0C0C0",
							}}
						>
							{/* {item.is_selected ? (
								<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							) : (
								<AddIcon />
							)} */}
							<Text variant="body1">{item.title}</Text>
						</CheckBoxDiv>
					))}
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
