/* Common imports */
import React from "react";

/* mui imports */
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import { Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/thunks";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));
const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomHomeWorkOutlinedIcon = styled(HomeWorkOutlinedIcon)(({ theme }) => ({
	margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

export const _x_service_subtype = ({
	search_id,
	service_type_value,
	service_subtype,
	dispatch,
}: {
	search_id: SearchFilterId;
	service_type_value: string;
	service_subtype: StateInterface["search_filters_state"]["service_subtype"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const router = useRouter();

	const handleUpdateServiceSubType = (value: Array<SearchFilterOptionTextActive>) => {
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: service_subtype.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		//leats check if the service subtype is selected

		let isServiceSubTypeSelected = service_subtype
			? service_subtype.selected_options.length !== 0
				? true
				: false
			: false;

		// lets update the the applied filter to true is data exists
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isServiceSubTypeSelected }));
	}, [service_subtype, dispatch, search_id]);

	return (
		<Container>
			<HeaderContainer>
				<CustomHomeWorkOutlinedIcon />
				<Heading variant="body2">
					{router.locale === "ar-AE"
						? "نوع الخدمة الفرعي"
						: router.locale === "kn-IN"
							? "ಸೇವಾ ಉಪಪ್ರಕಾರ"
							: "Service subtype"}
				</Heading>
				{/* {service_subtype?.selected_options.length != 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CheckBoxContainer>
				{service_subtype?.active_options
					.filter((option) =>
						[
							"civil_contractor",
							"painting",
							"pest_control",
							"interior_design",
							"interior_painting",
							"electrical_repair",
							"packers_and_movers",
						].includes(option.id),
					)
					.map((item: SearchFilterOptionTextActive) => {
						return (
							<CheckBoxDiv
								disableRipple
								disableTouchRipple
								key={item.id}
								onClick={() => {
									const isSelected = item.id === service_subtype?.selected_options[0]?.id;
									isSelected
										? handleUpdateServiceSubType([] as Array<SearchFilterOptionTextActive>)
										: handleUpdateServiceSubType([item] as Array<SearchFilterOptionTextActive>);
								}}
								sx={{
									background: item.id === service_subtype?.selected_options[0]?.id ? "rgba(252, 128, 25, 0.16)" : "",
									border:
										item.id === service_subtype?.selected_options[0]?.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
									fontWeight: item?.id === service_subtype?.selected_options[0]?.id ? "500" : "400",
								}}
							>
								{/* {item.id === service_subtype?.selected_options[0]?.id ? (
										<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
									) : (
										<AddIcon />
									)} */}
								<Text
									variant="body1"
									sx={{
										fontWeight: item?.id === service_subtype?.selected_options[0]?.id ? "500" : "400",
									}}
								>
									{item.title}
								</Text>
							</CheckBoxDiv>
						);
					})}
			</CheckBoxContainer>
		</Container>
	);
};
