/* Common Imports */

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { SvgIcon, Typography, styled } from "@mui/material";

/* Icons / Illustrations */

import { useRouter } from "next/router";
import Electrician from "../../../public/images/icons/services/electrician.svg";
import InteriorDesign from "../../../public/images/icons/services/interior_design.svg";
import Painting from "../../../public/images/icons/services/painting.svg";
import PestControl from "../../../public/images/icons/services/pest_control.svg";
// import Plumbing from "../../../public/images/icons/services/plumbing.svg";
import {
	cleaning_and_maintenance_options_active,
	construction_options_active,
	food_options_active,
	grooming_and_other_personal_care_options_active,
	home_and_appliance_repair_options_active,
	home_utilities_options_active,
	hospitality_options_active,
	interiors_and_decor_options_active,
	legal_and_finance_options_active,
	liaisoning_options_active,
	other_options_active,
	social_service_options_active,
	transport_options_active,
} from "../../../lib/data/search-filters/_x_service_subtype";
import Construction from "../../../public/images/icons/services/construction.svg";
import PackersMovers from "../../../public/images/icons/services/packers_and_movers.svg";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../redux-magic/thunks";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const CardsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	overflowX: "auto",
	overflowY: "hidden",
	whiteSpace: "nowrap",
	scrollbarWidth: "none", // Hide scrollbar for Firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide scrollbar for Chrome, Safari, and Opera
	},
}));

const CategoryCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "0.75rem 0.5rem 0rem 0.5rem",
	minWidth: "5.5rem",
	maxWidth: "5.25rem",
	height: "5.5rem",
	gap: "0rem",
	background: theme.palette.background.paper,
	borderRadius: "12px",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	padding: "0rem 0rem 0rem 0.25rem",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.675rem",
	lineHeight: "0.75rem",
	fontWeight: 500,
	color: theme.palette.text.secondary,
	textAlign: "center",
	wordBreak: "break-word", // Break words if necessary
	whiteSpace: "normal", // Allow normal wrapping of text
}));

const ServicesSection = ({
	dispatch,
	search_filter_state,
	searchanimationsettings,
	searchLocation,
}: {
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	searchLocation: any;
}) => {
	const router = useRouter();

	const service_categories = [
		{
			id: "construction",
			title: router.locale === "ar-AE" ? "البناء" : router.locale === "kn-IN" ? "ಕನ್ಸ್ಟ್ರಕ್ಷನ್" : "Construction",
			icon: Construction,
		},
		{
			id: "interior_design",
			title: router.locale === "ar-AE" ? "الديكورات الداخلية" : router.locale === "kn-IN" ? "ಇಂಟೀರಿಯರ್ಸ್" : "Interiors",
			icon: InteriorDesign,
		},
		{
			id: "painting",
			title: router.locale === "ar-AE" ? "الطلاء" : router.locale === "kn-IN" ? "ಪೇಂಟಿಂಗ್ " : "Painting",
			icon: Painting,
		},
		{
			id: "packers_and_movers",
			title:
				router.locale === "ar-AE"
					? "شركات النقل والتغليف"
					: router.locale === "kn-IN"
						? "ಪ್ಯಾಕರ್ಸ್  ಮತ್ತು ಮೂವರ್ಸ್"
						: "Packers & Movers",
			icon: PackersMovers,
		},
		{
			id: "electrician",
			title: router.locale === "ar-AE" ? "كهربائي" : router.locale === "kn-IN" ? "ಎಲೆಕ್ಟ್ರಿಷಿಯನ್" : "Electrician",
			icon: Electrician,
		},
		{
			id: "pest_control",
			title: router.locale === "ar-AE" ? "مكافحة الحشرات" : router.locale === "kn-IN" ? "ಕೀಟ ನಿಯಂತ್ರಣ" : "Pest Control",
			icon: PestControl,
		},
	];

	const handleCardClick = (category: string, searchLocation: any) => {
		const { title, coordinates } = searchLocation.value;
		const serviceMapping: { [key: string]: { serviceType: string; serviceSubtype: string } } = {
			construction: { serviceType: "construction", serviceSubtype: "civil_contractor" },
			interior_design: { serviceType: "interiors_and_decor", serviceSubtype: "interior_design" },
			painting: { serviceType: "construction", serviceSubtype: "painting" },
			packers_and_movers: { serviceType: "transport", serviceSubtype: "packers_and_movers" },
			plumbing: { serviceType: "home_and_appliance_repair", serviceSubtype: "plumbing_repair" },
			electrician: { serviceType: "home_and_appliance_repair", serviceSubtype: "electrical_repair" },
			pest_control: { serviceType: "cleaning_and_maintenance", serviceSubtype: "pest_control" },
		};

		const service = serviceMapping[category];
		if (!service) return;

		const { serviceType, serviceSubtype } = service;
		const callbackUrl = encodeURIComponent(
			`${process.env.PRODUCTION_URL}search?search_type=services&location=${title},${coordinates}&service_type=${serviceType}&service_subtype=${serviceSubtype}&locale=${router.locale || "en-In"}`,
		);
		const url = `${process.env.PRODUCTION_URL}loading.html?callbackUrl=${callbackUrl}`;

		window.location.href = url;
	};

	const handleCardFIrstTimeClick = (category: string) => {
		const serviceMapping: {
			[key: string]: { serviceType: string; serviceSubtype: string; titleType: string; titleSubType: string };
		} = {
			construction: {
				serviceType: "construction",
				serviceSubtype: "civil_contractor",
				titleType: "Construction",
				titleSubType: "Civil Contractor",
			},
			interior_design: {
				serviceType: "interiors_and_decor",
				serviceSubtype: "interior_design",
				titleType: "Interiors and Decor",
				titleSubType: "Interior Design",
			},
			painting: {
				serviceType: "construction",
				serviceSubtype: "painting",
				titleType: "Construction",
				titleSubType: "Painting",
			},
			packers_and_movers: {
				serviceType: "transport",
				serviceSubtype: "packers_and_movers",
				titleType: "Transport",
				titleSubType: "Packers and Movers",
			},
			plumbing: {
				serviceType: "home_and_appliance_repair",
				serviceSubtype: "plumbing_repair",
				titleType: "Home and Appliance Repair",
				titleSubType: "Plumbing Repair",
			},
			electrician: {
				serviceType: "home_and_appliance_repair",
				serviceSubtype: "electrical_repair",
				titleType: "Home and Appliance Repair",
				titleSubType: "Electrical Repair",
			},
			pest_control: {
				serviceType: "cleaning_and_maintenance",
				serviceSubtype: "pest_control",
				titleType: "Cleaning and Maintenance",
				titleSubType: "Pest Control",
			},
		};

		const service = serviceMapping[category];
		const { serviceType, serviceSubtype, titleType, titleSubType } = service;

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: "services",
						title: "Services",
						value: "services",
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: false,
			}),
		);
		/*

			*	service Type

		*/

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "service_type",
				newOptions: [
					{
						id: serviceType,
						title: titleType,
						value: serviceType,
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: "service_type",
				isVisible: true,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "service_type",
				isApplied: true,
			}),
		);
		/*

			*	service subType

		*/
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "service_subtype",
				newOptions: [
					{
						id: serviceSubtype,
						title: titleSubType,
						value: serviceSubtype,
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: "service_subtype",
				isVisible: true,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "service_subtype",
				isApplied: true,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "property_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: "property_type",
				isVisible: false,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "property_category",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: "property_category",
				isVisible: false,
			}),
		);

		const serviceSubTypeOptions =
			serviceType === "construction"
				? construction_options_active
				: serviceType === "interiors_and_decor"
					? interiors_and_decor_options_active
					: serviceType === "cleaning_and_maintenance"
						? cleaning_and_maintenance_options_active
						: serviceType === "home_utilities"
							? home_utilities_options_active
							: serviceType === "home_and_appliance_repair"
								? home_and_appliance_repair_options_active
								: serviceType === "legal_and_finance"
									? legal_and_finance_options_active
									: serviceType === "liaisoning"
										? liaisoning_options_active
										: serviceType === "transport"
											? transport_options_active
											: serviceType === "grooming_and_other_personal_care"
												? grooming_and_other_personal_care_options_active
												: serviceType === "social_service"
													? social_service_options_active
													: serviceType === "food"
														? food_options_active
														: serviceType === "hospitality"
															? hospitality_options_active
															: serviceType === "other"
																? other_options_active
																: construction_options_active;
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "service_subtype",
				newOptions: serviceSubTypeOptions,
			}),
		);
	};

	return (
		<MainContainer
			sx={{
				direction: router.locale === "ar-AE" ? "rtl" : "ltr",
			}}
		>
			<Heading
				variant="h6"
				sx={{
					fontWeight: router.locale === "ar-AE" ? 600 : 500,
				}}
			>
				{router.locale === "ar-AE"
					? "استكشف الخدمات"
					: router.locale === "kn-IN"
						? "ಸೇವೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ"
						: "Explore services"}
			</Heading>

			<CardsContainer>
				{service_categories.map((category, index) => (
					<CategoryCard
						key={index}
						onClick={() => {
							if (!searchLocation) {
								dispatch(
									setSearchAnimationDetailsThunk({
										StartAnimate: searchanimationsettings?.StartAnimate as boolean,
										TriggerSearch: searchanimationsettings?.TriggerSearch as boolean,
										ResetSearch: searchanimationsettings?.ResetSearch as boolean,
										PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
										HomeAccordion: false,
										OpenLocationDrawer: true,
									}),
								);
								handleCardFIrstTimeClick(category.id);
							} else {
								handleCardClick(category.id, searchLocation);
							}
						}}
						sx={{
							gap: index === 0 ? "0.75rem" : index === 3 ? "0.5rem" : "0rem",
						}}
					>
						<SubText variant="subtitle1">{category.title}</SubText>

						<SvgIcon
							component={category.icon}
							inheritViewBox={false}
							viewBox={index === 0 || index === 3 ? "0 0 40 40" : "0 0 85 80"}
							sx={{
								width: index === 0 || index === 3 ? "1.75rem" : "3.5rem",
								height: index === 0 || index === 3 ? "1.75rem" : "3.5rem",
							}}
						/>
					</CategoryCard>
				))}
			</CardsContainer>
		</MainContainer>
	);
};

export default ServicesSection;
