/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */
import { Button, Typography, useTheme } from "@mui/material";

import StateInterface from "../../../../redux-magic/state-interface";
import {
	setSearchAnimationDetailsThunk,
	setSearchFilterMetricesThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../../../redux-magic/store";
import { SearchFilterId, SearchFilterOptionTextActive } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk } from "../../../../redux-magic/thunks";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	flexWrap: "nowrap",
	alignItems: "center",
	gap: "1rem",
	paddingTop: "0.5rem",
	paddingBottom: "1rem",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));
const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const TransactionType = ({
	search_id,
	search_filter_state,
	dispatch,
	searchanimationsettings,
	setOpenBottomDrawerFilters,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["transaction_type"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	setOpenBottomDrawerFilters: Function;
}) => {
	const theme = useTheme();

	const handleUpdateTransactionType = (value: Array<SearchFilterOptionTextActive>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [
					{
						id: value[0].id === "Open for business" ? "ofb" : value[0].id.toLowerCase(),
						title: value[0].title,
						value: value[0].value === "Open for business" ? "ofb" : value[0].value.toLowerCase(),
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: true,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: "properties",
						title: "Properties",
						value: "properties",
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);
		dispatch(
			setSearchAnimationDetailsThunk({
				StartAnimate: searchanimationsettings.StartAnimate,
				TriggerSearch: true,
				ResetSearch: true,
				PropertySubCategory: searchanimationsettings.PropertySubCategory,
				HomeAccordion: false,
				OpenLocationDrawer: false,
			}),
		);
		dispatch(
			setSearchFilterMetricesThunk({
				boosted: [0],
				non_boosted: [0],
				filter_hash: "",
				no_of_boosted_results: 0,
				no_of_non_boosted_results: 0,
				total_no_of_results: 0,
				boosted_from_other_locations: [0],
				no_of_boosted_from_other_locations: 0,
			}),
		);
		setOpenBottomDrawerFilters(false);
	};

	React.useEffect(() => {
		/*
		 * lets check if the transaction type option is selected
		 */
		let isTransactionTypeSelected = search_filter_state
			? search_filter_state.selected_options.length !== 0
				? true
				: false
			: false;

		/*
		 * lets set the transaction type is applied to true if the transaction type is selected
		 */
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: isTransactionTypeSelected,
			}),
		);
	}, [search_filter_state, search_id, dispatch]);

	return (
		<Container>
			{search_filter_state.active_options
				.filter((gg) => ["buy", "rent"].includes(gg.id))
				.map((item: SearchFilterOptionTextActive) => (
					<CheckBoxDiv
						disableRipple
						disableTouchRipple
						key={item?.id}
						onClick={() => {
							if (search_filter_state?.selected_options[0]?.id !== item.id) {
								handleUpdateTransactionType([item] as Array<SearchFilterOptionTextActive>);
							}
						}}
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							background: item.id === search_filter_state?.selected_options[0]?.id ? "rgba(252, 128, 25, 0.16)" : "",
							border:
								item.id === search_filter_state?.selected_options[0]?.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
						}}
					>
						<Text
							variant="body1"
							sx={{
								fontWeight: item?.id === search_filter_state?.selected_options[0]?.id ? "500" : "400",
							}}
						>
							{item?.title === "Buy" ? "Sale" : "Rent"}
						</Text>
					</CheckBoxDiv>
				))}
		</Container>
	);
};

export default TransactionType;
