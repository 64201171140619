/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/thunks";
/* Component Imports */

import { Button, Divider, TextField, Typography } from "@mui/material";
import { useRouter } from "next/router";

/* Icon Imports */

import { Brightness1, ZoomOutMapOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	paddingBottom: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomZoomOutMapOutlinedIcon = styled(ZoomOutMapOutlined)(({ theme }) => ({
	margin: "0rem 0rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomSelectedIcon = styled(Brightness1)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const ChipsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

const Chip = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	minWidth: "2.25rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	width: "100%",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

const TextFieldsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	flexWrap: "nowrap",
	width: "100%",
	margin: "0rem",
	gap: "0.5rem",
	padding: "0.75rem 0rem 0rem 0rem",
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "0.825rem",
		fontWeight: "400",
		// lineHeight: "1rem",
		// height: "3.35rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: "500",
	lineHeight: "1rem",
	// padding: "0rem 0rem 1.5rem 0rem",
	color: theme.palette.text.secondary,
}));

const CustomSpan = styled("span")(({ theme }) => ({
	color: theme.palette.text.secondary,
	fontSize: "0.75rem",
}));

const AreaMobile = ({
	search_id,
	dispatch,
	area,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	area: StateInterface["search_filters_state"]["area"];
}) => {
	const router = useRouter();

	const [source, setSource] = React.useState<string>("");

	const [minArea, setMinArea] = React.useState<number>(0);
	const [maxArea, setMaxArea] = React.useState<number>(0);

	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	/* Setting initial values for budget */

	React.useEffect(() => {
		if (area.is_applied && source !== "main_filter") {
			setMinArea(
				area.is_applied &&
					typeof area.selected_options[0]?.value === "object" &&
					"min" in area.selected_options[0]?.value
					? area.selected_options[0].value.min
					: 0,
			);
			setMaxArea(
				area.is_applied &&
					typeof area.selected_options[0]?.value === "object" &&
					"max" in area.selected_options[0]?.value
					? area.selected_options[0].value.max
					: 0,
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [area]);

	const handleUpdateMinArea = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSource("main_filter");

		const value = e.target.value;
		const numberValue = parseInt(value);

		if (value === "") {
			setMinArea(0);
		} else {
			if (!isNaN(numberValue)) {
				setMinArea(numberValue);
			} else {
				setMinArea(0);
			}
		}

		if (maxArea !== 0 && numberValue > maxArea) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "area",
						title: "Area",
						value: {
							min: Number.isNaN(numberValue) ? 0 : numberValue,
							max: maxArea,
							unlock_max: maxArea === 0 || Number.isNaN(maxArea) ? true : false,
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);

		setSource("");
	};

	const handleUpdateMaxArea = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSource("main_filter");

		const value = e.target.value;
		const numberValue = parseInt(value);

		if (value === "") {
			setMaxArea(0);
		} else {
			if (!isNaN(numberValue)) {
				setMaxArea(numberValue);
			} else {
				setMaxArea(0);
			}
		}

		if (minArea !== 0 && numberValue < minArea) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "area",
						title: "Area",
						value: {
							min: minArea,
							max: Number.isNaN(numberValue) ? 0 : numberValue,
							unlock_max: numberValue === 0 || Number.isNaN(numberValue) ? true : false,
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);
	};

	/* Area Chips */

	const area_chips = [
		{
			min: 0,
			max: 600,
			label: "<600",
		},
		{
			min: 600,
			max: 1200,
			label: "600-1200",
		},
		{
			min: 1200,
			max: 2400,
			label: "1200-2400",
		},
		{
			min: 2400,
			max: 4000,
			label: "2400-4000",
		},
		{
			min: 4000,
			max: 6400,
			label: "4000-6400",
		},
		{
			min: 6400,
			max: 10000,
			label: "6400-10,000",
		},
		{
			min: 10000,
			max: 0,
			label: "10,000+",
		},
	];

	return (
		<Container>
			<HeaderContainer>
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "المساحة" : router.locale === "kn-IN" ? "ಏರಿಯಾ" : "Area"}
					<CustomSpan>
						{router.locale === "ar-AE" ? " (قدم مربع)" : router.locale === "kn-IN" ? " (ಸ್ಕ್ಫ್ಟ್)" : " (sqft)"}
					</CustomSpan>
				</Heading>
				{(minArea || maxArea) > 0 ? <CustomSelectedIcon /> : null}
			</HeaderContainer>

			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<ContentContainer>
				<ChipsContainer>
					{area_chips.map((item: any) => {
						const selected = minArea === item.min && maxArea === item.max;
						return (
							<Chip
								key={item.id}
								disableRipple
								disableTouchRipple
								onClick={() => {
									setMinArea(item.min);
									setMaxArea(item.max);

									dispatch(
										updateSearchFilterSelectedOptionsThunk({
											searchFilterId: search_id,
											newOptions: [
												{
													id: "area",
													title: "Area",
													value: {
														min: item.min,
														max: item.max,
														unlock_max: item.max === 0 ? true : false,
													},
												},
											],
										}),
									);

									dispatch(
										updateSearchFilterAppliedThunk({
											searchFilterId: search_id,
											isApplied: true,
										}),
									);

									setSource("");
								}}
								sx={{
									background: selected ? "#FC801933" : "",
									border: selected ? "1px solid #FC8019" : "1px solid #C0C0C0",
								}}
							>
								<Text
									variant="body1"
									sx={{
										fontWeight: selected ? 500 : 400,
									}}
								>
									{item.label}
								</Text>
							</Chip>
						);
					})}
				</ChipsContainer>

				<TextFieldsContainer>
					<CustomTextField
						value={minArea === 0 ? "" : minArea}
						id="outlined-basic"
						placeholder={
							router.locale === "ar-AE" ? "أدنى مساحة" : router.locale === "kn-IN" ? "ಕನಿಷ್ಠ ಏರಿಯಾ" : "Min area"
						}
						variant="outlined"
						type="text"
						size="small"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMinArea(e);
						}}
						error={errorMinPrice}
						// helperText={errorMinPrice ? "Value is more than max area" : NumberInWords(minArea)}
						autoComplete="off"
					/>
					<SubText>-</SubText>
					<CustomTextField
						value={maxArea === 0 ? "" : maxArea}
						id="outlined-basic"
						placeholder={
							router.locale === "ar-AE" ? "أقصى مساحة" : router.locale === "kn-IN" ? "ಗರಿಷ್ಠ ಏರಿಯಾ" : "Max area"
						}
						variant="outlined"
						type="text"
						size="small"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMaxArea(e);
						}}
						error={errorMaxPrice}
						// helperText={errorMinPrice ? "Value is less than min area" : NumberInWords(maxArea)}
						autoComplete="off"
					/>
				</TextFieldsContainer>
			</ContentContainer>
		</Container>
	);
};

export default AreaMobile;
