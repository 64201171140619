/* Common imports */

import React from "react";

/* mui imports */

import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";

import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/thunks";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const CustomListedByIcon = styled(Person2OutlinedIcon)(({ theme }) => ({
	// margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	// fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));
const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));
export const _y_listed_by = ({
	search_id,
	listed_by,
	dispatch,
	isService,
}: {
	search_id: SearchFilterId;
	listed_by: StateInterface["search_filters_state"]["listed_by"];
	dispatch: Function;
	isService: boolean;
}) => {
	const theme = useTheme();
	const router = useRouter();

	// const [slide, setSlide] = React.useState<boolean>(listed_by.is_accordion_open);

	const handleUpdateListedBy = (value: Array<SearchFilterOptionTextActive>) => {
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: listed_by.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the listed by option is selected
		let isPropertyCategorySelected = listed_by ? (listed_by.selected_options.length !== 0 ? true : false) : false;

		// lets set the property category is applied to true if the property category is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isPropertyCategorySelected }));
	}, [listed_by, search_id, dispatch]);

	// const isServiceOptions

	return (
		<Container>
			<HeaderContainer>
				<CustomListedByIcon />
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "مدرج بواسطة" : router.locale === "kn-IN" ? "ಪಟ್ಟಿ ಮಾಡಿದವರು" : "Listed by"}
				</Heading>
				{/* {listed_by.is_applied && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<CheckBoxContainer>
				{listed_by.active_options
					.filter((item) => (isService ? item.title : item.title !== "Professional"))
					.map((item: SearchFilterOptionTextActive) => (
						<CheckBoxDiv
							disableRipple
							disableTouchRipple
							key={item.id}
							onClick={() => {
								const isSelected = item.id === listed_by?.selected_options[0]?.id;
								isSelected
									? handleUpdateListedBy([] as Array<SearchFilterOptionTextActive>)
									: handleUpdateListedBy([item] as Array<SearchFilterOptionTextActive>);
							}}
							sx={{
								background: item.id === listed_by?.selected_options[0]?.id ? "#FC801933" : "",
								border: item.id === listed_by?.selected_options[0]?.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
							}}
						>
							{/* {item.is_selected ? (
								<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							) : (
								<AddIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							)} */}
							<Text
								variant="body1"
								sx={{
									fontWeight: item.id === listed_by?.selected_options[0]?.id ? "500" : "400",
								}}
							>
								{item.title === "Landowner"
									? "Owner"
									: item.title === "Professional"
										? "Service Professional"
										: item.title === "Developer"
											? "Builder"
											: item.title}
							</Text>
						</CheckBoxDiv>
					))}
			</CheckBoxContainer>
		</Container>
	);
};
