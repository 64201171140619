import TuneIcon from "@mui/icons-material/Tune";
import { Typography, styled, useTheme } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import React from "react";
import { useInView } from "react-intersection-observer";
import {
	Amenities,
	Area,
	Bathrooms,
	Bedrooms,
	Budget,
	BusinessProfileType,
	Furnishing,
	LandFacing,
	ListedBy,
	PropertyCategory,
	PropertyStatus,
	PropertyType,
	PurchaseType,
	ServiceSubtype,
	ServiceType,
	UnitFacing,
} from ".";
// import EndOfLocationResultMobile from "../../public/images/illustrations/end-of-location-search-mobile.svg";
// import EndOfLocationResult from "../../public/images/illustrations/end-of-location-search.svg";
// import NoSearchResultsMobile from "../../public/images/illustrations/no-results-search-mobile.svg";
// import NoSearchResults from "../../public/images/illustrations/no-results-search.svg";
import { useMediaQuery } from "@mui/system";
import { useRouter } from "next/router";
import NoBusiness from "../../public/images/icons/search/no_business.svg";
import NoProperty from "../../public/images/icons/search/no_property.svg";
import NoServices from "../../public/images/icons/search/no_services.svg";
import StateInterface from "../../redux-magic/state-interface";
import { setSearchAnimationDetailsThunk } from "../../redux-magic/thunks";
import BusinessProfileCardGridView from "../cards/BusinessProfileCards/BusinessProfileCardGridView";
import OFBCardGridView from "../cards/OFBCardGridMini/OFBCardFetcher";
import PropertyCardGridView from "../cards/PropertyCardGridMiniTwo/PropertyCardFetcher";
import ServiceCardGridView from "../cards/ServiceCardGridMiniTwo/ServiceCardFetcher";
import BeegruButton from "../common-components/buttons/BeegruButton";
import SearchAfterAnimationComplete from "../page-sections/Index/SearchAfterAnimationComponent";
import SearchMobile from "../page-sections/Index/SearchMobileComponent/SearchMobile";
import AppliedFilters from "./AppliedFilters";
const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	paddingTop: "4rem",
	[theme.breakpoints.down("md1190")]: {
		display: "flex",
		justifyContent: "flex-start",
		width: "100%",
	},
}));
const RowDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	gap: "1rem",
	padding: "0.5rem 0rem 0rem 0rem",
	[theme.breakpoints.up("sm")]: {
		paddingTop: "1rem 0rem 0rem 0rem",
	},
}));

const SnackbarContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	[theme.breakpoints.down("md1220")]: {
		justifyContent: "flex-start",
		gap: "0.5rem",
	},
}));

const DesktopFilterDiv = styled("div")(({ theme }) => ({
	display: "flex",
	position: "sticky",
	flexDirection: "column",
	width: "25%",
	gap: "1rem",
	[theme.breakpoints.down("md1190")]: {
		display: "none",
	},
}));

const DesktopFilterDivSomething = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
	rowGap: "0.75rem",
	flexWrap: "wrap",
	padding: "0rem 0rem 0rem 0rem",
	[theme.breakpoints.up("xxs")]: {
		display: "flex",
		padding: "0rem 0.5rem 0rem 0.5rem",
	},
	/* XS breakpoint */

	[theme.breakpoints.up("xs")]: {
		padding: "0rem 0.75rem 0rem 0.75rem",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		// gap: "0.5rem", // 3 cards
		// rowGap: "0.75rem", //3 cards
		gap: "0.75rem", // 2 cards
		rowGap: "0.75rem", // 2cards
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		// gap: "0.5rem", // 3 cards
		// rowGap: "0.75rem", //3 cards
		gap: "1rem", // 2 cards
		rowGap: "1rem", // 2 cards
	},
	/* XSPLUS breakpoint */
	[theme.breakpoints.up("xsPlus")]: {
		padding: "0rem 1.5rem 0rem 1.5rem",
	},
	/* SM breakpoint */
	[theme.breakpoints.only("sm")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	[theme.breakpoints.up("sm")]: {
		padding: "0rem ",
	},
	/* MD breakpoint */
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		gap: "1rem",
		rowGap: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		width: "75%",
	},
}));

const LoadMoreDiv = styled("div")(({ theme }) => ({
	width: "100%",
	height: "7.5rem",
	pointerEvents: "none",
	cursor: "none",
	background: "transparent",
	// display: "flex",
	// flexDirection: "row",
	// justifyContent: "flex-start",
	alignItems: "center",
	// flexWrap: "wrap",
	// padding: "0rem 0rem 0rem 0rem",
	// height: "100%",
	// gap: "0.5rem",
	// rowGap: "0.75rem",
	// [theme.breakpoints.up("xxs")]: {
	// 	display: "flex",
	// 	padding: "0rem 0.5rem 0rem 0.5rem",
	// },
	// /* XS breakpoint */

	// [theme.breakpoints.up("xs")]: {
	// 	padding: "0rem 0.75rem 0rem 0.75rem",
	// },
	// /*galaxy S8+ - 360*/
	// [theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
	// 	// gap: "0.5rem", // 3 cards
	// 	// rowGap: "0.75rem", //3 cards
	// 	gap: "0.75rem", // 2 cards
	// 	rowGap: "0.75rem", // 2cards
	// },
	// /*iphone 14 pro max - 430*/
	// [theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
	// 	// gap: "0.5rem", // 3 cards
	// 	// rowGap: "0.75rem", //3 cards
	// 	gap: "1rem", // 2 cards
	// 	rowGap: "1rem", // 2 cards
	// },
	// /* XSPLUS breakpoint */
	// [theme.breakpoints.up("xsPlus")]: {
	// 	padding: "0rem 1.5rem 0rem 1.5rem",
	// },
	// /* SM breakpoint */
	// [theme.breakpoints.only("sm")]: {
	// 	padding: "0rem 3rem 0rem 3rem",
	// },
	// [theme.breakpoints.up("sm")]: {
	// 	padding: "0rem ",
	// },
	// /* MD breakpoint */
	// [theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
	// 	gap: "1rem",
	// 	rowGap: "1rem",
	// },
	// [theme.breakpoints.up("md1190")]: {
	// 	width: "75%",
	// },
}));

const BannerDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	height: "auto",
	margin: "1rem 0rem 0rem 0rem",
}));

const FilterComponents = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
	search_filters_metrices,
	session,
	profile_context,
	PropertyIds,
	search_api_response,
	CollectionName,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
	session: any;
	profile_context: any;
	PropertyIds: any;
	search_api_response: StateInterface["SeacrhApiResponse"];
	CollectionName: any;
}) => {
	const theme = useTheme();

	const router = useRouter();

	const { ref, inView } = useInView({ threshold: 0 });

	const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const [blockLoadMore, setBlockLoadMore] = React.useState(false);

	React.useEffect(() => {
		/*

		* when in view lets change the searchanimationsettings.trigger to false

			&& blockLoadMore is used to block the load more button when the search filters are changed

		*/
		if (inView && blockLoadMore) {
			// setIsLoadingLoadmorediv(true);
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: true,
					TriggerSearch: true,
					PropertySubCategory: searchanimationsettings.PropertySubCategory,
					ResetSearch: false,
					HomeAccordion: false,
					OpenLocationDrawer: false,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView, blockLoadMore]);

	React.useEffect(() => {
		/*

		* Checking if search filter state is changed then block the inview from triggering the api. it will give us new search filters data. so block it. simple

		*/
		if (search_filter_state) {
			setBlockLoadMore(true);
		}
	}, [search_filter_state]);

	let displayPropertyStatus = true;

	switch (search_filter_state.property_type.selected_options[0]?.id) {
		case "land_residential":
		case "land_commercial":
		case "land_agricultural":
		case "land_industrial":
		case "mining":
			displayPropertyStatus = false;
	}

	/*

	* Checking if the distance is above 5km or not. if it is then we will store it in isDistanceAboveThreshold to true.

	*/

	const EndOfLocationComponent = () => {
		if (windowWidth > 590 && search_api_response.total_no_of_results === 0) {
			return (
				<BannerDiv
					sx={{
						gap: "1.5rem",
					}}
				>
					<Typography
						sx={{
							fontSize: "1.25rem",
							fontWeight: 500,
							color: theme.palette.text.secondary,
							lineHeight: "2rem",
							letterSpacing: "0.00938rem",
						}}
					>
						{router.locale === "ar-AE"
							? "نتائج أخرى قريبة"
							: router.locale === "kn-IN"
								? "ಇತರ ಸಮೀಪದ ಫಲಿತಾಂಶಗಳನ್ನು ತೋರಿಸುತ್ತಿದೆ"
								: "Showing other nearby results"}
					</Typography>
				</BannerDiv>
			);
		} else if (windowWidth <= 590 && search_api_response.total_no_of_results === 0) {
			return (
				<BannerDiv
					sx={{
						gap: "1rem",
					}}
				>
					<Typography
						sx={{
							fontSize: "0.875rem",
							fontWeight: 400,
							color: theme.palette.text.secondary,
							lineHeight: "1rem",
						}}
					>
						{router.locale === "ar-AE"
							? "نتائج أخرى قريبة"
							: router.locale === "kn-IN"
								? "ಇತರ ಸಮೀಪದ ಫಲಿತಾಂಶಗಳನ್ನು ತೋರಿಸುತ್ತಿದೆ"
								: "Showing other nearby results"}
					</Typography>
				</BannerDiv>
			);
		} else {
			return null;
		}
	};

	/*

	* Getting the index of banner_location from PropertyIds

	*/
	const bannerLocationIndex = PropertyIds.indexOf("banner_location");

	const ComponentToRender = PropertyIds.map((propertyId: any, key: any) => {
		const isBannerLocation = propertyId === "banner_location";
		/*

		* Lets check if the propertyId banner_location exists or not.
		* bannerLocationIndex === -1 this is to check if the banner_location is not present in the PropertyIds. if not its a relavant property
		* if it is then lets set the DivId to relevant_property or unrelevant_property

		*/
		const DivId = bannerLocationIndex === -1 || key < bannerLocationIndex ? "relevant_property" : "unrelevant_property";

		const renderMobileCard = () => {
			switch (CollectionName) {
				case "ofb":
					return (
						<OFBCardGridView
							OFBId={propertyId}
							id={propertyId}
							dispatch={dispatch}
							session={session}
							profile_context={profile_context}
							source="search"
							key={key}
						/>
					);
				case "properties":
					return (
						<PropertyCardGridView
							PropertyId={propertyId}
							id={DivId}
							dispatch={dispatch}
							session={session}
							profile_context={profile_context}
							source="search"
							key={key}
							search_log_id={search_api_response?.search_log_id as string}
							// filter_hash={search_api_response?.filter_hash as string}
						/>
					);
				case "business_profiles":
					return (
						<BusinessProfileCardGridView
							_id={propertyId}
							source="search"
							key={key}
						/>
					);
				case "services":
					return (
						<ServiceCardGridView
							ServiceId={propertyId}
							dispatch={dispatch}
							session={session}
							profile_context={profile_context}
							source="search"
							key={key}
						/>
					);
				default:
					return null;
			}
		};

		// Conditionally render the mobile card or EndOfLocationComponent
		return !isBannerLocation ? renderMobileCard() : <EndOfLocationComponent key={key} />;
	});

	const [showAdvancedFilters, setShowAdvancedFilters] = React.useState(false);

	let BudgetTrigger = search_filter_state.business_profile_type.is_applied ? true : false;
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<React.Fragment>
			<SnackbarContainer>
				<SearchAfterAnimationComplete
					dispatch={dispatch}
					search_filter_state={search_filter_state}
					searchanimationsettings={searchanimationsettings}
				/>
				<SearchMobile
					search_filter_state={search_filter_state}
					dispatch={dispatch}
					searchanimationsettings={searchanimationsettings}
					search_filters_metrices={search_filters_metrices}
					search_api_response={search_api_response}
				/>
			</SnackbarContainer>

			<CustomDiv
				sx={{
					"@keyframes CustomFadeOut": {
						"0%": { opacity: 0 },
						"25%": { opacity: 0 },
						"50%": { opacity: 0 },
						"75%": { opacity: 0.5 },
						"100%": { opacity: 1 },
					},
					animation: searchanimationsettings.StartAnimate ? "CustomFadeOut 0.5s linear 1" : "none",
					padding: "0rem 0rem 0.5rem 0rem",
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				<RowDiv>
					<DesktopFilterDiv>
						<AppliedFilters
							search_filter_state={search_filter_state}
							dispatch={dispatch}
							searchanimationsettings={searchanimationsettings}
							search_type={search_filter_state.search_type?.selected_options[0]?.id as string}
						/>

						{!BudgetTrigger && search_filter_state.budget.is_visible && (
							<Budget
								search_id="budget"
								dispatch={dispatch}
								budget={search_filter_state.budget}
								transaction_type={search_filter_state.transaction_type}
							/>
						)}
						{search_filter_state.service_type.is_visible && !search_filter_state.property_type.is_visible && (
							<ServiceType
								search_id="service_type"
								service_category_value={search_filter_state.service_category?.selected_options[0]?.value as string}
								dispatch={dispatch}
								service_type={search_filter_state.service_type}
							/>
						)}
						{search_filter_state.service_subtype.is_visible && !search_filter_state.property_type.is_visible && (
							<ServiceSubtype
								search_id="service_subtype"
								service_type_value={search_filter_state.service_type?.selected_options[0]?.id as string}
								dispatch={dispatch}
								service_subtype={search_filter_state.service_subtype}
							/>
						)}
						{search_filter_state.property_category.is_visible && !search_filter_state.service_type.is_visible && (
							<PropertyCategory
								search_id="property_category"
								property_category={search_filter_state.property_category}
								dispatch={dispatch}
								search_filter_state={search_filter_state}
							/>
						)}
						{search_filter_state.property_category?.selected_options[0]?.id !== "warehousing" &&
							search_filter_state.property_type.is_visible &&
							!search_filter_state.service_type.is_visible &&
							!["public_infrastructure", "joint_development", "joint_venture"].includes(
								search_filter_state.property_category?.selected_options[0]?.id,
							) && (
								<PropertyType
									search_id="property_type"
									property_category_value={search_filter_state.property_category?.selected_options[0]?.value as string}
									dispatch={dispatch}
									property_type={search_filter_state.property_type}
								/>
							)}
						{!BudgetTrigger && search_filter_state.listed_by.is_visible && (
							<ListedBy
								search_id="listed_by"
								listed_by={search_filter_state.listed_by}
								dispatch={dispatch}
								isService={search_filter_state.search_type.selected_options[0]?.title === "Services" ? true : false}
							/>
						)}
						{search_filter_state.transaction_type.is_applied &&
							!search_filter_state.transaction_type.selected_options[0].title.includes("Rent") &&
							search_filter_state.property_category.is_applied && (
								<PurchaseType
									search_id="transaction_type"
									dispatch={dispatch}
								/>
							)}
						{search_filter_state.bedrooms.is_visible && search_filter_state.property_type.is_visible && (
							<Bedrooms
								search_id="bedrooms"
								dispatch={dispatch}
								bedrooms={search_filter_state.bedrooms}
							/>
						)}
						{showAdvancedFilters &&
							search_filter_state.area.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Area
									search_id="area"
									dispatch={dispatch}
									area={search_filter_state.area}
								/>
							)}
						{showAdvancedFilters &&
							search_filter_state.property_status.is_visible &&
							displayPropertyStatus &&
							search_filter_state.property_type.is_visible && (
								<PropertyStatus
									search_id="property_status"
									dispatch={dispatch}
									property_status={search_filter_state.property_status}
									property_type={search_filter_state.property_type}
									transaction_type={search_filter_state.transaction_type}
									posession_date={search_filter_state.possession_date}
								/>
							)}
						{showAdvancedFilters &&
							search_filter_state.unit_facing.is_visible &&
							search_filter_state.property_type.is_visible &&
							router.locale !== "ar-AE" && (
								<UnitFacing
									search_id="unit_facing"
									dispatch={dispatch}
									unit_facing={search_filter_state.unit_facing}
								/>
							)}
						{showAdvancedFilters &&
							search_filter_state.land_facing.is_visible &&
							search_filter_state.property_type.is_visible &&
							router.locale !== "ar-AE" && (
								<LandFacing
									search_id="land_facing"
									dispatch={dispatch}
									land_facing={search_filter_state.land_facing}
								/>
							)}
						{showAdvancedFilters &&
							search_filter_state.furnishing.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Furnishing
									search_id="furnishing"
									dispatch={dispatch}
									furnishing={search_filter_state.furnishing}
								/>
							)}
						{/* {showAdvancedFilters && search_filter_state.security_deposit.is_visible && (
							<SecurityDeposit
								search_id="security_deposit"
								dispatch={dispatch}
								security_deposit={search_filter_state.security_deposit}
							/>
						)} */}
						{showAdvancedFilters &&
							search_filter_state.amenities.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Amenities
									search_id="amenities"
									dispatch={dispatch}
									amenities={search_filter_state.amenities}
								/>
							)}

						{/* {showAdvancedFilters &&
							search_filter_state.balconies.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Balconies
									search_id="balconies"
									dispatch={dispatch}
									balconies={search_filter_state.balconies}
								/>
							)} */}
						{showAdvancedFilters &&
							search_filter_state.bathrooms.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Bathrooms
									search_id="bathrooms"
									dispatch={dispatch}
									bathrooms={search_filter_state.bathrooms}
								/>
							)}
						{/* {search_filter_state.plot_dimensions.is_visible && search_filter_state.property_type.is_visible && (
							<PlotDimensions
								search_id="plot_dimensions"
								dispatch={dispatch}
								plot_dimensions={search_filter_state.plot_dimensions}
							/>
						)} */}

						{/* {search_filter_state.possession_date.is_visible && search_filter_state.property_type.is_visible && (
							<PossessionDate
								search_id="possession_date"
								dispatch={dispatch}
								possession_date={search_filter_state.possession_date}
							/>
						)} */}
						{/* {search_filter_state.available_from.is_visible && search_filter_state.property_type.is_visible && (
							<AvailableFrom
								search_id="available_from"
								dispatch={dispatch}
								available_from={search_filter_state.available_from}
							/>
						)} */}

						{/* {showAdvancedFilters &&
							search_filter_state.floors.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Floors
									search_id="floors"
									dispatch={dispatch}
									floors={search_filter_state.floors}
								/>
							)} */}

						{search_filter_state.business_profile_type.is_visible &&
							search_filter_state.business_profile_type.is_applied && (
								<BusinessProfileType
									search_id="business_profile_type"
									dispatch={dispatch}
									business_profile_type={search_filter_state.business_profile_type}
								/>
							)}

						{search_filter_state.business_profile_type.is_applied ||
						search_filter_state.property_category?.selected_options[0]?.id === "warehousing" ||
						search_filter_state.search_type.selected_options[0]?.title === "Services" ||
						(search_filter_state.search_type.selected_options[0]?.title === "Properties" &&
							search_filter_state.property_type.is_applied === false) ? null : (
							<BeegruButton
								flavor="primary"
								variant="contained"
								size="large"
								sx={{
									dsiaplay: "flex",
									marginTop: "1rem",
									[theme.breakpoints.down("md")]: {
										width: "100%",
									},
								}}
								onClick={() => {
									setShowAdvancedFilters(!showAdvancedFilters);
								}}
							>
								{showAdvancedFilters
									? router.locale === "ar-AE"
										? "إخفاء الفلاتر المتقدمة"
										: router.locale === "kn-IN"
											? "ಉನ್ನತ ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಮರುದರ್ಶಿಸಿ"
											: "Hide advanced filters"
									: router.locale === "ar-AE"
										? "إظهار الفلاتر المتقدمة"
										: router.locale === "kn-IN"
											? "ಉನ್ನತ ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಮರುದರ್ಶಿಸಿ"
											: "Show advanced filters"}
							</BeegruButton>
						)}
						{/* <ServiceCategory
							search_id="service_category"
							dispatch={dispatch}
							service_category={search_filter_state.service_category}
						/> */}
					</DesktopFilterDiv>
					{/* {showAnimation ? (
						// Show Lottie animation
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								height: "100vh",
							}}
						/>
					) : ( */}

					<DesktopFilterDivSomething>
						{search_api_response &&
						search_api_response.total_no_of_results === 0 &&
						search_api_response.boosted.length === 0 &&
						search_api_response.non_boosted.length === 0 &&
						!smDown ? (
							<BannerDiv>
								<Typography
									sx={{
										fontSize: "0.875rem",
										fontWeight: 400,
										color: theme.palette.text.secondary,
										lineHeight: "1rem",
										[theme.breakpoints.down("sm")]: { fontSize: "0.875rem" },
									}}
								>
									{router.locale === "ar-AE"
										? "لم يتم العثور على نتائج. حاول تغيير الفلاتر."
										: router.locale === "kn-IN"
											? "ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಸಿಗಲಿಲ್ಲ. ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಬದಲಾಯಿಸಿ"
											: "No results found. Try changing the filters"}
								</Typography>
							</BannerDiv>
						) : search_api_response &&
						  search_api_response.total_no_of_results === 0 &&
						  search_api_response.boosted.length === 0 &&
						  search_api_response.non_boosted.length === 0 &&
						  smDown ? (
							<BannerDiv
								sx={{
									display: "flex",
									alignItems: "center",
									gap: "0.5rem",
								}}
							>
								<SvgIcon
									component={
										CollectionName === "properties"
											? NoProperty
											: CollectionName === "services"
												? NoServices
												: CollectionName === "business_profiles"
													? NoBusiness
													: null
									}
									inheritViewBox
									sx={{
										width: "100px",
										height: "100px",
									}}
								/>
								<Typography
									variant="body1"
									sx={{
										fontSize: "1rem",
										fontWeight: 400,
										color: theme.palette.text.primary,
										lineHeight: "1rem",
										[theme.breakpoints.down("sm")]: { fontSize: "0.875rem" },
									}}
								>
									{router.locale === "ar-AE"
										? `لم يتم العثور على ${
												CollectionName === "properties"
													? "عقار"
													: CollectionName === "services"
														? "خدمة"
														: CollectionName === "business_profiles"
															? "ملف تجاري"
															: "نتائج"
											}`
										: router.locale === "kn-IN"
											? `${
													CollectionName === "properties"
														? "ಆಸ್ತಿ"
														: CollectionName === "services"
															? "ಸೇವೆ"
															: CollectionName === "business_profiles"
																? "ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್"
																: "ಫಲಿತಾಂಶ"
												} ಕಂಡುಬಂದಿಲ್ಲ`
											: `No ${
													CollectionName === "properties"
														? "property"
														: CollectionName === "services"
															? "service"
															: CollectionName === "business_profiles"
																? "business profile"
																: "results"
												} found`}
								</Typography>
								<Typography
									variant="body2"
									sx={{
										display: "flex",
										alignContent: "center",
										justifyContent: "center",
										alignItems: "center",
										fontSize: "0.875rem",
										fontWeight: 400,
										color: theme.palette.text.secondary,
										lineHeight: "1rem",
										[theme.breakpoints.down("sm")]: { fontSize: "0.875rem" },
									}}
								>
									{router.locale === "ar-AE" ? (
										"لم يتم العثور على نتائج. حاول تغيير الفلاتر."
									) : router.locale === "kn-IN" ? (
										"ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಸಿಗಲಿಲ್ಲ. ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಬದಲಾಯಿಸಿ"
									) : (
										<>
											Edit
											<TuneIcon
												sx={{
													marginLeft: "0.25rem",
													marginRight: "0.25rem",
													width: "1rem",
													height: "1rem",
												}}
											/>
											filters to modify your search
										</>
									)}
								</Typography>
							</BannerDiv>
						) : null}
						{ComponentToRender}
						{/* <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}> */}
						{PropertyIds && PropertyIds.length < 4 ? null : (
							<LoadMoreDiv ref={ref}>
								{/* {smDown ? (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											height: "100%",
											padding: "1.5rem 0rem 0rem 0rem",
										}}
									>
										<CircularProgress size={25} />
									</Box>
								) : null} */}
							</LoadMoreDiv>
						)}
						{/* </div> */}
						{/* {search_api_response && search_api_response.total_no_of_results } */}
					</DesktopFilterDivSomething>
					{/* )} */}
				</RowDiv>
			</CustomDiv>
		</React.Fragment>
	);
};

export default FilterComponents;
