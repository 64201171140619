/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */
import { Button, Typography, useTheme } from "@mui/material";
import {
	cleaning_and_maintenance_options_active,
	construction_options_active,
	home_and_appliance_repair_options_active,
	interiors_and_decor_options_active,
	transport_options_active,
} from "../../../../lib/data/search-filters/_x_service_subtype";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../../../redux-magic/thunks";
/* redux imports */

/* stylings */
const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: "0.5rem",
	flexWrap: "wrap",
	paddingTop: "0.5rem",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

const ServiceSubTypeComponent = ({
	search_id,
	search_filter_state,
	dispatch,
	search_type,
	service_type,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["service_subtype"];
	dispatch: Function;
	search_type: string;
	service_type: string;
}) => {
	const [checked, setChecked] = React.useState(false);
	const theme = useTheme();
	const handleClickServiceRadioType = (value: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: value,
			}),
		);
		/*

		* change is_selected to true for the selected option

		*/
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: search_filter_state.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);
	};

	React.useEffect(() => {
		if (!checked) {
			dispatch(
				updateSearchFilterActiveOptionsThunk({
					searchFilterId: search_id,
					newOptions:
						service_type === "construction"
							? construction_options_active
							: service_type === "cleaning_and_maintenance"
								? cleaning_and_maintenance_options_active
								: service_type === "interiors_and_decor"
									? interiors_and_decor_options_active
									: service_type === "transport"
										? transport_options_active
										: service_type === "home_and_appliance_repair"
											? home_and_appliance_repair_options_active
											: [],
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);
	return (
		<Container>
			{search_filter_state.active_options
				.filter((option) =>
					[
						"civil_contractor",
						"painting",
						"pest_control",
						"interior_design",
						"interior_painting",
						"electrical_repair",
						"packers_and_movers",
					].includes(option.id),
				)
				.map((item: SearchFilterOption) => (
					<CheckBoxDiv
						disableRipple
						disableTouchRipple
						key={item?.id}
						onClick={() => {
							if (search_filter_state?.selected_options[0]?.id !== item.id) {
								handleClickServiceRadioType([item] as Array<SearchFilterOption>);
							}
						}}
						sx={{
							fontSize: "1rem",
							backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
							color: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801933" : "transparent",
							border:
								search_filter_state?.selected_options[0]?.id === item.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
							"&.MuiChip-clickable:hover": {
								backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801933" : "transparent",
								boxShadow: "none",
							},
						}}
					>
						<Text
							variant="body1"
							sx={{
								fontWeight: item?.id === search_filter_state?.selected_options[0]?.id ? "500" : "400",
							}}
						>
							{item.title}
						</Text>
					</CheckBoxDiv>
				))}
		</Container>
	);
};
export default ServiceSubTypeComponent;
